import React, { useEffect, useState } from 'react';
import { Input, Button, Tooltip } from 'antd';
import TextEditor from '../../../Components/TextEditor'; // Import the TextEditor component
import '../WorkPages.less';
import { useProjectContext } from '../../../Context/ProjectContext';


const AddSingleCard = () => {
  const { project, update } = useProjectContext();
  const [card, setCard] = useState({ title: '', content: '' });
  const [isPosted, setIsPosted] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (project) {
        setCard({ title: project.overview_title, content: project.overview_description });
        setIsPosted(project.overview_title || project.overview_description)
      }
    }, 1)
    return () => {
      clearTimeout(timer)
    }
  }, [project])

  const handleCardChange = (field, value) => {
    setCard({ ...card, [field]: value });
  };

  const handlePost = () => {
    setIsPosted(true);
    update.project({ overview_title: card.title, overview_description: card.content })
  };

  const handleEdit = () => {
    setIsPosted(false);
  };

  return (
    <div className="pmt-add-card-container">
      {isPosted ? (
        <div className="pmt-posted-card">

          <div className="pmt-posted-card-title-container">
            <h3 className="pmt-posted-card-title">{card.title}</h3>
            <div className="pmt-edit-button-container">
              <button onClick={handleEdit} className="pmt-edit-button">
                <i className="fi fi-rr-edit"></i>
              </button>
            </div>
          </div>

          <div className="pmt-posted-card-content" dangerouslySetInnerHTML={{ __html: card.content }} />
        </div>
      ) : (
        <div className="pmt-card-container">
          <div className="pmt-card-editor">

            <Input
              placeholder="Project Description"
              value={card.title}
              onChange={(e) => handleCardChange('title', e.target.value)}
              className="pmt-card-title-input"
            />
            <TextEditor
              value={card.content}
              onChange={(value) => handleCardChange('content', value)}
              className="pmt-text-editor"
            />
            <div className="pmt-card-buttons">
              <Button onClick={handlePost} type="primary" className="pmt-post-button">
                Post
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddSingleCard;

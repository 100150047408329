import React from 'react';
import { Row, Col, Descriptions, Select, DatePicker, ConfigProvider } from 'antd';
import SingleAvatar2 from '../../Components/SingleAvatar2';
import EditableTimeSpentCell from '../../Components/EditableTimeSpentCell'; // Assuming you still need this component
import getStatusTag from '../../Components/StatusTags2'; // Adjust the path if necessary
import dayjs from 'dayjs';

const { Option } = Select;

const TaskSummary = ({ task, onTaskUpdate, project }) => {

  return (
    <div className="tdm-task-summary">
      <Row gutter={16} className="tdm-task-summary-row">
        <Col span={24} className="tdm-task-summary-col tdm-task-summary-col-left">
          <Descriptions title="" bordered column={2} className="tdm-descriptions">
            <Descriptions.Item label="Assignee:" className="tdm-descriptions-item">
              <Select
                value={task.assignee}
                onChange={(value) => onTaskUpdate('assignee', value)}
                style={{ width: '100%' }}
                className="tdm-assignee-select border-1"
                allowClear
              >
                {project.members.map(member => (
                  <Option key={member.user_id} value={member.user_id}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <SingleAvatar2 name={member.user.f_name + " " + member.user.l_name} />
                      <span style={{ marginLeft: '8px' }}>{member.user.f_name + " " + member.user.l_name}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </Descriptions.Item>
            <Descriptions.Item label="Status:" className="tdm-descriptions-item">
              <Select
                value={task.status}
                onChange={(value) => onTaskUpdate('status', value)}
                style={{ width: '100%' }}
                className="tdm-status-select"
              >
                <Option value="Not Started">{getStatusTag("Not Started")}</Option>
                {/* <Option value="On Hold">{getStatusTag("On Hold")}</Option> */}
                <Option value="In Progress">{getStatusTag("In Progress")}</Option>
                {/* <Option value="In Review">{getStatusTag("In Review")}</Option> */}
                <Option value="Completed">{getStatusTag("Completed")}</Option>
              </Select>
            </Descriptions.Item>
            <Descriptions.Item label="Hours Alloted:" className="tdm-descriptions-item">
              <EditableTimeSpentCell
                value={task.hours_allotted}
                onChange={(value) => onTaskUpdate('hours_allotted', value)}
                className="tdm-time-spent-input border-1"
              />
            </Descriptions.Item>
            <Descriptions.Item label="Time Spent:" className="tdm-descriptions-item">
              <EditableTimeSpentCell
                value={task.timeSpent}
                onChange={(value) => onTaskUpdate('timeSpent', value)}
                className="tdm-time-spent-input border-1"
              />
            </Descriptions.Item>
            <Descriptions.Item label="Start Date:" className="tdm-descriptions-item">
              <ConfigProvider locale="en">
                <DatePicker
                  value={task.start_date ? dayjs(task.start_date) : null}
                  onChange={(date) => onTaskUpdate('start_date', date ? date.format("YYYY-MM-DD") : null)}
                  style={{ width: '100%' }}
                  format="YYYY-MM-DD"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  className="tdm-due-date-picker border-1"
                />
              </ConfigProvider>
            </Descriptions.Item>
            <Descriptions.Item label="Due Date:" className="tdm-descriptions-item">
              <ConfigProvider locale="en">
                <DatePicker
                  value={task.dueDate ? dayjs(task.dueDate) : null}
                  onChange={(date) => onTaskUpdate('dueDate', date ? date.format("YYYY-MM-DD") : null)}
                  style={{ width: '100%' }}
                  format="YYYY-MM-DD"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  className="tdm-due-date-picker border-1"
                />
              </ConfigProvider>
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={12} className="tdm-task-summary-col tdm-task-summary-col-right">
          <Descriptions bordered column={1} className="tdm-descriptions">
            {/* <Descriptions.Item label="Project Manager:" className="tdm-descriptions-item">
              <Select
                value={projectManager}
                onChange={handleProjectManagerChange}
                style={{ width: '100%' }}
                className="tdm-project-manager-select border-1"
              >
                {projectManagerOptions}
              </Select>
            </Descriptions.Item> */}
            {/* <Descriptions.Item label="Priority:" className="tdm-descriptions-item">
              <Select
                value={priority}
                onChange={handlePriorityChange}
                style={{ width: '100%' }}
                className="tdm-priority-select"
              >
                {priorityOptions}
              </Select>
            </Descriptions.Item> */}
          </Descriptions>
        </Col>
      </Row>
    </div>
  )
}

export default TaskSummary;

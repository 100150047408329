import React from 'react';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const countDays = (startDate, endDate) => {
  return endDate.diff(startDate, 'day') + 1; // Including the start date
};

const TimelineColumn = {
  title: 'Timeline',
  render: (text, record, index) => {
    if(!record.start_date){
      return <>No Start Date</>;
    }
    if(!record.due_date){
      return <>No End Date</>;
    }
    const taskCreated = dayjs(record.start_date);
    const dueDate = dayjs(record.due_date);

    const timeSpan = countDays(taskCreated, dueDate);
    const daysLeft = dueDate.diff(dayjs(), 'day') + 1;

    return (
      <div>
        <div style={{ paddingBottom: 10 }}>
          <span>{taskCreated.format('DD/MM/YY')}</span> {/* Display the start date */}
          <span className="arrow">→</span> {/* Display an arrow between the dates */}
          <span>{dueDate.format('DD/MM/YY')}</span> {/* Display the end date */}
        </div>
        <div className="pt-workdays-container">
          <span className="pt-total-workdays-text">
            <span className="pt-timeline-value-text">{timeSpan}</span> Workdays Total.
          </span>
          <span className="pt-workdays-left-text">
            {daysLeft > 0 && record.status !== "Completed" ? (
              <>
                <span className="pt-timeline-value-text">{timeSpan}</span> Workdays Left.
              </>
            ) : (
              'Task Finished.'
            )}
          </span>
        </div>
      </div>
    );
  }

}
export default TimelineColumn;

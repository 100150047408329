// ClientsTableColumns/ContactInfoColumn.js

import { Dropdown, message } from 'antd';
import React, { useState } from 'react';

function formateAddress(record) {
  const parts = []
  if (record.address) {
    parts.push(record.address)
  }
  if (record.city) {
    parts.push(record.city)
  }
  if (record.country) {
    parts.push(record.country)
  }
  return parts.join(', ')
}
const RenderContactInfo = ({ record }) => {
  const [visible, setVisible] = useState(false);
  const formatedAddres = formateAddress(record)

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    message.success({
      content: 'Copied!',
    });
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const renderContactInfo = (record) => (
    <div className="ct-more-dropdown-content">
      <div className="ct-more-dropdown-item">
        <i className="fi fi-rr-marker"></i>
        {formatedAddres}
        <div className="clone-icon-container" onClick={() => copyToClipboard(formatedAddres)}>
          <i className="fi fi-rr-clone"></i>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div className="ct-client-email">
        <i className="fi fi-rr-at"></i>
        {record.contact.email}
        <i className="fi fi-rr-clone" onClick={() => copyToClipboard(record.contact.email)}></i>
      </div>
      {record.contact.phone && <div className="ct-client-phone">
        <i className="fi fi-rr-phone-flip"></i>
        {record.contact.phone}
        <i className="fi fi-rr-clone" onClick={() => copyToClipboard(record.contact.phone)}></i>
      </div>}
      {record.website && <div className="ct-client-phone">
        <i className="fi fi-rr-cursor"></i>
        {record.website}
        <i className="fi fi-rr-clone" onClick={() => copyToClipboard(record.website)}></i>
      </div>}
      {formatedAddres && <Dropdown
        overlay={renderContactInfo(record)}
        trigger={['click']}
        visible={visible}
        onVisibleChange={handleVisibleChange}
      >
        <a className="ct-contact-info-more-button">More</a>
      </Dropdown>}
    </div>
  );
};

const ContactInfoColumn = {
  title: 'Contact Info',
  key: 'contactInfo',
  className: 'ct-contact-info',
  render: (text, record) => <RenderContactInfo record={record} />,
};

export default ContactInfoColumn;

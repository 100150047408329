// file path : src\services\automation.service.js

import axios from "axios";
import { apiBaseUrl } from "../config";
import { getAuthHeader } from "./auth.service";

export function fetchAutomations(page = 0, rowsPerPage = 20, sortByColumns = []) {
    const query = new URLSearchParams("")
    if (page) {
        query.set('page', page.toString());
    }
    if (rowsPerPage) {
        query.set('per_page', rowsPerPage.toString());
    }
    if (sortByColumns && sortByColumns.length > 0) {
        sortByColumns.forEach(element => {
            if (element.key && element.order)
                query.set(element.key, element.order);
        });
    }
    let url = [apiBaseUrl + "automations", query.toString()].join("/?")
    return axios.get(url, getAuthHeader())
}
export function fetchCompanyAutomations(page = 0, rowsPerPage = 20, sortByColumns = []) {
    const query = new URLSearchParams("")
    if (page) {
        query.set('page', page.toString());
    }
    if (rowsPerPage) {
        query.set('per_page', rowsPerPage.toString());
    }
    if (sortByColumns && sortByColumns.length > 0) {
        sortByColumns.forEach(element => {
            if (element.key && element.order)
                query.set(element.key, element.order);
        });
    }
    let url = [apiBaseUrl + "company/automations", query.toString()].join("/?")
    return axios.get(url, getAuthHeader())
}
export function fetchAutomation(automationId) {
    return axios.get(apiBaseUrl + `automations/${automationId}`, getAuthHeader())
}
export function updateAutomation(automationId, values) {
    return axios.put(apiBaseUrl + `automations/${automationId}`, values, getAuthHeader())
}
export function removeMember(automationId, user_id) {
    return axios.delete(apiBaseUrl + `automations/${automationId}/members/${user_id}`, getAuthHeader())
}
export function addMember(automationId, user_id) {
    return axios.post(apiBaseUrl + `automations/${automationId}/member`, { user_id }, getAuthHeader())
}
export function addAutomation(name, client_id) {
    return axios.post(apiBaseUrl + `automations`, { name, client_id }, getAuthHeader())
}
export function requestAutomation(dataToPost) {
    return axios.post(apiBaseUrl + `company/automations`, dataToPost, getAuthHeader())
}

export function createAutomation(values) {
    return axios.post(apiBaseUrl + 'automations', values, getAuthHeader())
}

export function automationAutocomplete(value) {
    return axios.get(apiBaseUrl + `automations/autocomplete/${value}`, getAuthHeader())
}

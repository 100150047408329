// ProjectsTableColumns/TeamCell.js
import React from 'react';
import CustomAvatarGroup from '../../../Components/UserAvatars2';
import TeamMembersModalWrapper from './TeamMembersModalWrapper';

const TeamCell = ({ record }) => {
  const manager = record.client?.manager
  return (
    <div className="pt-team-container">
      <div className="pt-team-cell">
        <CustomAvatarGroup members={record.members.map(({ user }, index) => ({ id: user.id, name: user.f_name + " " + user.l_name }))} maxCount={5} />
        <TeamMembersModalWrapper project={record} />
      </div>
      <div className="pt-pm-text"><i class="fi fi-sr-boss"></i>{manager ? manager.f_name + " " + manager.l_name : "Not Assigned"} </div>
    </div>

  );
};

export default TeamCell;

// File Path: src\Pages\WorkPages\TeamMembersModalWrapper.js

import React, { useState } from 'react';
import { message } from 'antd';
import { addMember, removeMember } from '../../services/project.service';
import { useProjectContext } from '../../Context/ProjectContext';
import TeamMembersModal from '../../Modals/TeamMembersModal/TeamMembersModal';
import AddMemberButton from '../../Components/AddMemberButton';

const TeamMembersModalWrapper = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { project, setProject } = useProjectContext();

  const onAddMember = (projectId, memberId) => {
    addMember(projectId, memberId)
      .then((res) => {
        setProject({ ...res.data });
        message.success('Team Member Added');
      })
      .catch((err) => {
        console.log('onAddMember failed', err);
        message.error('Team Member Add Failed');
      });
  };

  const onRemoveMember = (projectId, userId) => {
    return removeMember(projectId, userId)
      .then(() => {
        const memberIndex = project.members.findIndex((item) => item.user_id === userId);
        project.members.splice(memberIndex, 1);
        setProject({ ...project });
        message.success('Team Member Removal Successful');
      })
      .catch((err) => {
        console.log('onRemoveMember failed', err);
        message.error('Team Member Removal Failed');
        throw err;
      });
  };

  const toggleModal = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      <AddMemberButton project={project} handleAddMember={toggleModal} />
      <TeamMembersModal
        project={project}
        visible={isVisible}
        onClose={toggleModal}
        onAddMember={onAddMember}
        onRemoveMember={onRemoveMember}
      />
    </>
  );
};

export default TeamMembersModalWrapper;

import React, { useEffect, useState } from 'react';
import { Pagination } from 'antd';
import 'dayjs/locale/en';
import './ProjectsPages.less';
import ProjectsTableSort from './ProjectsTableColumns/ProjectsTableSort';
import RowOptions from './ProjectsTableColumns/RowOptions';
import SkeletonTable from '../../Components/SkeletonTable';
import { ProjectsProvider, useProjectsContext } from '../../Context/ProjectsContext';
import { canEditProject, fetchProjects } from '../../services/project.service';
import ProjectNameColumn from './ProjectsTableColumns/ProjectNameColumn';
import TeamCell from './ProjectsTableColumns/TeamCell';
import DateColumn from './ProjectsTableColumns/DateColumn';
import ProgressColumn from './ProjectsTableColumns/ProgressColumn';
import StatusColumn from './ProjectsTableColumns/StatusColumn';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getRoleName } from '../../services/auth.service';
import BreadcrumbComponent from '../../Components/breadcrumb';
import { fetchClient } from '../../services/client.service';
import { appSubDir } from '../../config';

const Loading = <div style={{ padding: '24px 0' }}>
  <div style={{ overflowX: 'auto' }}>
    <SkeletonTable rows={5} columns={5} />
  </div>
</div>
const TableRow = ({ row, index, columns }) => {
  const user = useSelector((state) => state.auth.user);
  return (
    <tr className="pt-table-row" style={{ position: 'relative' }}>
      {columns.map((column, colIndex) => (
        <td key={column.accessor} className="pt-cell">
          {column.render ? column.render(null, row, index) : row[column.accessor]}
        </td>
      ))}
      {canEditProject(row, user) && <td className="row-options-cell">
        <RowOptions row={row} />
      </td>}
    </tr>
  )
};

const MainComponent = () => {
  const user = useSelector((state) => state.auth.user);
  const { client_id } = useParams()
  const {
    currentPage, setCurrentPage,
    projects, setProjects,
    loading, setLoading,
    totalRows, setTotalRows,
    sortByColumns,
    rowsPerPage, setRowsPerPage
  } = useProjectsContext()
  const [client, setClient] = useState(null);
  const columns = [
    { Header: 'Project', accessor: 'name', render: (text, record) => ProjectNameColumn.render(text, record, user,client_id) },
    {
      Header: 'Progress', accessor: 'progressBar', render: (text, record) => (
        <ProgressColumn project={record} />
      )
    },
    {
      Header: 'Status', accessor: 'status', render: (text, record, index) => (
        <StatusColumn project={record} index={index} />
      )
    },
    {
      Header: 'Timeline', accessor: 'date', render: (text, record, index) => (
        <DateColumn record={record} index={index} />
      )
    },
    {
      Header: 'Team', accessor: 'team', render: (text, record) => (
        <TeamCell record={record} />
      )
    },
  ];


  // Page Data Loader
  function loadData() {
    setLoading(true);
    fetchProjects(
      currentPage,
      rowsPerPage,
      client_id ? [...sortByColumns, ...[{ key: 'cid', order: client_id }]] : sortByColumns,
      getRoleName(user)
    ).then((res) => {
      setProjects(res.data.data);
      setTotalRows(res.data.total);
    }).finally(() => {
      setLoading(false);
    });
    if (client_id) {
      fetchClient(client_id).then((res) => {
        setClient(res.data);
      })
    } else {
      setClient(null)
    }
  }
  // Fetch clients from the backend
  useEffect(() => {
    const timer = setTimeout(() => {
      loadData()
    }, 1)
    return () => {
      clearTimeout(timer)
    }
  }, [client_id, currentPage, rowsPerPage, sortByColumns]);
  return (
    <div className="pt-projects-container">
      {client ? <BreadcrumbComponent items={[
        { title: '', link: appSubDir, icon: 'fi-rr-home' },
        { title: 'Clients', link: appSubDir + 'clients' },
        { title: client.company_name, link: appSubDir + 'clients/' + client.id + "/profile" },
        { title: 'Projects' },
      ]} /> : null}
      <p className="main-page-title">
        <span className="main-page-title-icon">
          <i className="fi fi-rr-briefcase"></i>
        </span>
        All Projects
      </p>
      {loading ? Loading : <>
        <ProjectsTableSort />
        <div className="main-table-main-container">
          <div className="main-table-container">
            <table className="main-table">
              <thead className="main-table-head">
                <tr>
                  {columns.map((column) => (
                    <th key={column.accessor} className="at-header-cell">
                      {column.Header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {projects.map((row, index) => (
                  <TableRow key={index} row={row} index={index} columns={columns} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>}
      <Pagination
        current={currentPage}
        pageSize={rowsPerPage}
        total={totalRows}
        onChange={setCurrentPage}
        className="pt-pagination"
      />
    </div>
  );
};
const Projects = () => {
  return (
    <ProjectsProvider>
      <MainComponent />
    </ProjectsProvider>
  )
}

export default Projects;

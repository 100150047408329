// ProjectsTableColumns/ProjectNameColumn.js

import React from 'react';
import ProjectUpsertModal from '../ProjectUpsertModal';
import { hasRole } from '../../../services/auth.service';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
const ProjectName = ({ project }) => {
  const user = useSelector(state => state.auth.user);
  if (hasRole(user, 'client')) {
    return <div className="pt-project-name">
      <i className="fi fi-rr-briefcase"></i>{project.name}
    </div>
  } else {
    return <Link to={'/projects/' + project.id} className="pt-project-name">
      <i className="fi fi-rr-briefcase"></i>{project.name}
    </Link>
  }

}
const ClientName = ({ record }) => {
  const user = useSelector(state => state.auth.user);
  const { client_id } = useParams()
  if (hasRole(user, 'super-admin') && client_id) {
    return <ProjectUpsertModal project={record} />
  } else if (hasRole(user, 'super-admin') && !client_id) {
    return <div className="pt-client-name">
      <i className="fi fi-rr-city"></i>{record.client?.company_name}
      <ProjectUpsertModal project={record} />
    </div>
  } else {
    return null
  }

}
const ProjectNameColumn = {
  title: 'Project Name',
  key: 'projectName',
  render: (text, record, user, client_id) => (
    <div className="pt-project-info-cell">
      <ProjectName project={record} />
      <ClientName record={record} />

    </div>
  )
};

export default ProjectNameColumn;

import axios from "axios";
import { apiBaseUrl, storageBaseUrl } from "../config";
import { getAuthHeader } from "./auth.service";

export function getLogoUrl(fileName) {
    return storageBaseUrl + "logos/" + fileName + ".webp"
}
export function getBannerUrl(fileName) {
    return storageBaseUrl + "banners/" + fileName + ".webp"
}
export function fetchCompanyProfile() {
    return axios.get(apiBaseUrl + `company/profile`, getAuthHeader())
}
export function uploadLogo(formData) {
    return axios.post(apiBaseUrl + `company/logo`, formData, getAuthHeader())
}
export function uploadBanner(formData) {
    return axios.post(apiBaseUrl + `company/banner`, formData, getAuthHeader())
}
export function updateCompanyProfile(values) {
    return axios.put(apiBaseUrl + `company/profile`, values, getAuthHeader())
}

import React, { useState } from 'react';
import { useProjectContext } from '../../Context/ProjectContext';

const TableHeader = ({ headerGroup }) => {
  const { dragColumn, setDragColumn, columnOrder, setColumnOrder } = useProjectContext()
  const [dragLocked, setDragLocked] = useState(false)

  function handleColumnResize(e, columnId, index) {
    const rowElm = e.target.closest('tr')
    const cellElm1 = rowElm.querySelector(`[data-column-id="${columnId}"]`);
    const cellElm2 = rowElm.querySelector(`[data-column-id="${columnOrder[index + 1]}"]`);
    const props1 = cellElm1.getBoundingClientRect()
    const props2 = cellElm2.getBoundingClientRect()
    const lower = props1.left
    const upper = props2.right
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    setDragLocked(true)

    function handleMouseMove(event) {
      if ((lower + 100 < event.clientX && event.clientX < upper - 100)) {
        const cellElm1NewWidth = event.clientX - lower;
        const cellElm2NewWidth = upper - lower - cellElm1NewWidth
        cellElm1.style.width = `${cellElm1NewWidth}px`;
        cellElm2.style.width = `${cellElm2NewWidth}px`;
      }
    }

    function handleMouseUp() {
      setDragLocked(false)
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }
  };
  function getClassNames(columnId) {
    const classNames = ['pmt-header-cell']
    if (dragColumn.to > -1 && columnOrder.findIndex((item) => (item === columnId)) === dragColumn.to) {
      classNames.push('drop-target')
    }
    return classNames.join(" ")
  }
  function onDragStart(columnId) {
    dragColumn.from = columnOrder.findIndex((item) => (item === columnId))
    setDragColumn({ ...dragColumn })
  }
  function onDragOver(event, columnId) {
    event.preventDefault(); // Necessary to allow the drop
    if (dragColumn.from > -1) {
      const targetIndex = columnOrder.findIndex((item) => (item === columnId))
      if (dragColumn.from !== targetIndex && columnOrder[targetIndex] !== 'taskName') {
        dragColumn.to = targetIndex
        setDragColumn({ ...dragColumn })
      }
    }
  }
  function onDrop(event) {
    event.preventDefault();
    if (dragColumn.from > -1 && dragColumn.from !== dragColumn.to) {
      const columnName = columnOrder[dragColumn.from]
      columnOrder.splice(dragColumn.from, 1)
      columnOrder.splice(dragColumn.to, 0, columnName)
      setColumnOrder([...columnOrder])
    }
    setDragColumn({ from: -1, to: -1 })
  }
  return (
    <tr {...headerGroup.getHeaderGroupProps()} className="pmt-header-row">
      {headerGroup.headers.map((column, index) => {
        return (
          <th
            key={column.id}
            className={getClassNames(column.id)}
            style={{ width: column.width + "%" }}
            data-column-id={column.id}
          >
            <div
              className='th'
              onDragStart={() => onDragStart(column.id)}
              onDragOver={(e) => onDragOver(e, column.id)}
              onDrop={onDrop}
              draggable={(!dragLocked && column.id !== "taskName")}
            >
              {column.render('Header')}
            </div>
            {['assignee', 'status', 'dueDate'].includes(column.id) && <div className="resizer" onMouseDown={(e) => { handleColumnResize(e, column.id, index) }} />}
          </th>
        )
      })}
    </tr>
  );
};

export default TableHeader;

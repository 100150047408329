// File Path: src/Context/ProjectContext.js

import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { clientsData as initialClientsData } from "../Data/ProjectsSampleData";
import {
  updateSubtask,
  addNewSubtask,
  addSubTaskAction,
  groupTasksAction,
  sortTaskAction,
  addTaskAction,
  updateTaskAction,
  glowEffect,
  beforeSetTasks,
  foccusTaskInput,
  addSectionAction,
  foccusSubTaskInput,
} from "../Utils/taskActions";
import { addClient, removeClient, updateClient } from "../Utils/clientActions";
import { moveColumn } from "../Utils/dndActions";
import { updateProject } from "../services/project.service";
import { useSelector } from "react-redux";
import { getRoleName } from "../services/auth.service";
import { deleteSubTask, deleteTask } from "../services/project-task.service";

/**
 * Creates a context for managing project-related data and actions.
 */
const ProjectContext = createContext();

/**
 * Provides project-related state and actions to its child components.
 *
 * @param {Object} props - Component props.
 * @param {React.ReactNode} props.children - Child components.
 * @returns {JSX.Element} The context provider component.
 */
export const ProjectProvider = ({ children }) => {
  const user = useSelector((state) => state.auth.user);
  const role = getRoleName(user);

  // State variables for managing clients, tasks, and project details.
  const [clientsData, setClientsData] = useState(initialClientsData);
  const [tasks, setTasks] = useState([]);
  const [project, setProject] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [columnOrder, setColumnOrder] = useState([
    "taskName",
    "assignee",
    "status",
    "dueDate",
    "hours_allotted",
    "timeSpent",
  ]);
  const [groupByColumns, setGroupByColumns] = useState(["section"]);
  const [sortByColumns, setSortByColumns] = useState([]);
  const [taskGroups, setTaskGroups] = useState([]);
  const [dragData, setDragData] = useState({ from: -1, to: -1 }); // Drag data for tasks
  const [dragColumn, setDragColumn] = useState({ from: -1, to: -1 }); // Drag data for columns
  const focusTaskIdRef = useRef(null);

  /**
   * Effect hook to group and sort tasks whenever tasks, sortByColumns, or groupByColumns change.
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      if (groupByColumns.length > 0) {
        const newTaskGroups = groupTasksAction(tasks, groupByColumns);
        newTaskGroups.forEach((item) => {
          sortTaskAction(item.tasks, sortByColumns);
        });
        setTaskGroups([...newTaskGroups]);
      } else {
        setTaskGroups([]);
      }
    }, 1);
    return () => {
      clearTimeout(timer);
    };
  }, [tasks, sortByColumns, groupByColumns]);

  /**
   * Toggles the visibility of a modal.
   */
  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  /**
   * Handles the reordering of columns.
   *
   * @param {number} sourceIndex - The starting index of the column being moved.
   * @param {number} destinationIndex - The index where the column is being moved to.
   */
  const handleMoveColumn = (sourceIndex, destinationIndex) => {
    setColumnOrder((prevOrder) =>
      moveColumn(prevOrder, sourceIndex, destinationIndex)
    );
  };

  return (
    <ProjectContext.Provider
      value={{
        // State variables
        focusTaskIdRef,
        clientsData,
        tasks,
        setTasks,
        project,
        setProject,
        isModalVisible,
        groupByColumns,
        setGroupByColumns,
        sortByColumns,
        setSortByColumns,
        taskGroups,
        setTaskGroups,
        dragData,
        setDragData,
        dragColumn,
        setDragColumn,
        isTableLoading,
        setIsTableLoading,
        columnOrder,
        setColumnOrder,

        // Methods
        toggleModal,

        /**
         * Adds a new subtask to a task.
         *
         * @param {string} taskId - The ID of the task.
         * @returns {Array} Updated tasks array.
         */
        addNewSubtask: (taskId) =>
          setTasks((prevTasks) => {
            console.log("addNewSubtask context called with:", taskId);
            return addNewSubtask(prevTasks, taskId);
          }),

        /**
         * Adds a subtask below the specified subtask.
         *
         * @param {number} taskIndex - The index of the task in the tasks array.
         * @param {number} subtaskIndex - The index of the subtask.
         * @returns {Array} Updated tasks array.
         */
        addSubtaskBelow: (taskIndex, subtaskIndex) =>
          setTasks((prevTasks) => {
            console.log("addSubtaskBelow context called with:", {
              taskIndex,
              subtaskIndex,
            });
            return addSubTaskAction(prevTasks, taskIndex, subtaskIndex);
          }),

        // Remove methods
        remove: {
          /**
           * Removes a task from the tasks array.
           *
           * @param {number} taskIndex - The index of the task to remove.
           * @returns {Promise} A promise that resolves when the task is deleted from the server.
           */
          task: (taskIndex) => {
            // Removing task from the local state
            const newTasks = [...tasks];
            newTasks.splice(taskIndex, 1);
            beforeSetTasks(newTasks);
            setTasks(newTasks);
            // Removing task from the server
            return deleteTask(project.id, tasks[taskIndex].taskId,role);
          },
          subTask: (taskIndex, subtaskIndex) => {
            setTasks((prevTasks) =>
              prevTasks.map((task, index) => {
                if (index === taskIndex) {
                  return {
                    ...task,
                    subtasks: task.subtasks.filter((_, subIndex) => subIndex !== subtaskIndex,role),
                  };
                }
                return task;
              })
            );
            return deleteSubTask(project.id, tasks[taskIndex].taskId, tasks[taskIndex].subtasks[subtaskIndex].sub_task_id);
          }
        },

        // Add methods
        add: {
          /**
           * Adds a new task to the project.
           *
           * @param {number} groupIndex - The index of the group to add the task to.
           * @param {number|boolean} [taskIndex=false] - The index to insert the task at.
           * @returns {Object} The newly added task.
           */
          task: (groupIndex, taskIndex = -1) => {
            const newTask = addTaskAction(
              project.id,
              tasks,
              taskGroups,
              groupIndex,
              groupByColumns,
              taskIndex
            );
            sortTaskAction(tasks, sortByColumns);
            beforeSetTasks(tasks);
            // Update the ref and increment the version
            focusTaskIdRef.current = newTask.taskId;
            setTasks([...tasks]);

            // foccusTaskInput(newTask.taskId, 100);
            return newTask;
          },

          /**
           * Adds a new subtask to a task.
           *
           * @param {number} taskIndex - The index of the task to add the subtask to.
           * @param {number|boolean} [subTaskIndex=false] - The index to insert the subtask at.
           * @param {boolean} [focus=true] - Whether to focus the input field after adding.
           * @returns {Object} The newly added subtask.
           */
          subTask: (taskIndex, subTaskIndex = false, focus = true) => {
            const newSubTask = addSubTaskAction(
              project.id,
              tasks,
              taskIndex,
              subTaskIndex,
              role
            );
            beforeSetTasks(tasks);
            setTasks([...tasks]);
            if (focus) {
              foccusSubTaskInput(tasks[taskIndex].taskId, newSubTask.subTaskId);
            }
            return newSubTask;
          },

          /**
           * Adds a new section to the tasks.
           *
           * @param {string} section - The name of the new section.
           */
          section: (section) => {
            const newTask = addSectionAction(project.id, tasks, section);
            beforeSetTasks(tasks);
            setTasks([...tasks]);
            foccusTaskInput(newTask.taskId, 50);
          },
        },

        // Update methods
        update: {
          /**
           * Updates the project with new data.
           *
           * @param {Object} dataToUpdate - The data to update in the project.
           */
          project: (dataToUpdate) => {
            updateProject(project.id, dataToUpdate, role);
            setProject({ ...project, ...dataToUpdate });
          },

          /**
           * Updates a task with new data.
           *
           * @param {Object} taskData - The data identifying the task.
           * @param {string} newTaskKey - The key of the data to update.
           * @param {any} newTaskValue - The new value to set.
           * @param {boolean} [focusInput=false] - Whether to focus the input field after updating.
           */
          task: (taskData, newTaskKey, newTaskValue, focusInput = false) => {
            const { groupIndex, groupTaskIndex, taskIndex } = taskData;
            if (groupIndex !== undefined) {
              // If Table is a group Table
              const group = taskGroups[groupIndex];
              const task = group.tasks[groupTaskIndex];
              if (task[newTaskKey] === newTaskValue) return;
              updateTaskAction(
                tasks,
                taskIndex,
                { [newTaskKey]: newTaskValue },
                sortByColumns,
                role
              );
              setTasks([...tasks]);

              glowEffect(
                `.pmt-group-table:nth-child(${groupIndex + 2
                }) tr.pmt-row:nth-child(${groupTaskIndex + 1})`,
                focusInput
              );
            } else {
              const task = tasks[taskIndex];
              if (task[newTaskKey] === newTaskValue) return;
              updateTaskAction(
                tasks,
                taskIndex,
                { [newTaskKey]: newTaskValue },
                sortByColumns,
                role
              );
              setTasks([...tasks]);
              glowEffect(`tr.pmt-row:nth-child(${taskIndex + 1})`, focusInput);
            }
          },

          /**
           * Updates a subtask with new data.
           *
           * @param {Object} subTaskData - The data identifying the subtask.
           * @param {string} newTaskKey - The key of the data to update.
           * @param {any} newTaskValue - The new value to set.
           */
          subTask: (subTaskData, newTaskKey, newTaskValue) => {
            const {
              groupIndex,
              groupTaskIndex,
              groupSubTaskIndex,
              taskIndex,
              subTaskIndex,
            } = subTaskData;
            // console.log("Updating Sub Task", { groupIndex, groupTaskIndex, groupSubTaskIndex, taskIndex, subTaskIndex, newTaskKey, newTaskValue })

            const subTask = tasks[taskIndex].subtasks[subTaskIndex];
            if (subTask[newTaskKey] === newTaskValue) return;
            setTasks((prevTasks) => {
              return updateSubtask(
                prevTasks,
                taskIndex,
                subTaskIndex,
                { [newTaskKey]: newTaskValue },
                role
              );
            });
          },

          fromLocal: {
            /**
             * Updates sortByColumns state from local storage.
             *
             * @param {string} projectId - The ID of the project.
             */
            sortByColumns: (projectId) => {
              // console.log('updateSortByColumns context called with:', newData);
              const local = window.localStorage.getItem(
                "sort-by-columns-" + projectId
              );
              if (local) {
                setSortByColumns(JSON.parse(local));
              }
            },

            /**
             * Updates groupByColumns state from local storage.
             *
             * @param {string} projectId - The ID of the project.
             */
            groupByColumns: (projectId) => {
              // console.log('updateSortByColumns context called with:', newData);
              const local = window.localStorage.getItem(
                "group-by-columns-" + projectId
              );
              if (local) {
                setGroupByColumns(JSON.parse(local));
              }
            },
          },

          /**
           * Updates the sortByColumns state and saves it to local storage.
           *
           * @param {Array} newData - The new sortByColumns data.
           */
          sortByColumns: (newData) => {
            // console.log('updateSortByColumns context called with:', newData);
            window.localStorage.setItem(
              "sort-by-columns-" + project.id,
              JSON.stringify(newData)
            );
            setSortByColumns([...newData]);
          },

          /**
           * Updates the groupByColumns state and saves it to local storage.
           *
           * @param {Array} newData - The new groupByColumns data.
           */
          groupByColumns: (newData) => {
            // console.log('updateSortByColumns context called with:', newData);
            window.localStorage.setItem(
              "group-by-columns-" + project.id,
              JSON.stringify(newData)
            );
            setGroupByColumns([...newData]);
          },
        },

        // Team member methods (to be implemented)
        addTeamMember: (newMember) => { },
        removeTeamMember: (memberId) => { },
        updateTeamMember: (memberId, updatedMember) => { },

        /**
         * Adds a new client.
         *
         * @param {Object} newClient - The new client data.
         */
        addClient: (newClient) =>
          setClientsData((prevClients) => addClient(prevClients, newClient)),

        /**
         * Removes a client.
         *
         * @param {string} clientId - The ID of the client to remove.
         */
        removeClient: (clientId) =>
          setClientsData((prevClients) => removeClient(prevClients, clientId)),

        /**
         * Updates a client's data.
         *
         * @param {string} clientId - The ID of the client to update.
         * @param {Object} updatedClient - The updated client data.
         */
        updateClient: (clientId, updatedClient) =>
          setClientsData((prevClients) =>
            updateClient(prevClients, clientId, updatedClient)
          ),

        /**
         * Moves a column to a new position.
         *
         * @param {number} sourceIndex - The starting index of the column.
         * @param {number} destinationIndex - The index to move the column to.
         */
        moveColumn: handleMoveColumn,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

/**
 * Custom hook to use the ProjectContext.
 *
 * @returns {Object} The context value.
 */
export const useProjectContext = () => {
  return useContext(ProjectContext);
};

import React, { useState, useEffect, useRef } from 'react';
import { Input } from 'antd';
import { hoursToString, hoursToString2, parseInput, stringToHours } from '../services/common.functions';

const EditableTimeSpentCell = ({ value, className, onChange }) => {
  const [decimalHours, setDecimalHours] = useState(value);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null)


  const onInputChange = (e) => {
    const value = e.target.value
    setDecimalHours(stringToHours(parseInput(value)));
  };

  const onFocus = () => {
    setIsEditing(true);
    setTimeout(() => {
      if (inputRef && inputRef.current) {
        inputRef.current.input.value = hoursToString(decimalHours)
      }
    }, 10)
  };

  const onBlur = () => {
    setIsEditing(false);
    onChange && onChange(decimalHours);
  };

  if (isEditing) {
    return (
      <Input
        ref={inputRef}
        className={className}
        onChange={onInputChange}
        onBlur={onBlur}
        placeholder="00:00"
      />
    );
  } else {

    return (
      <Input
        className={className}
        value={hoursToString2(decimalHours)}
        onFocus={onFocus}
        placeholder="00:00"
      />
    );
  }
};

export default EditableTimeSpentCell;

import React from 'react';
import EditableTimeSpentCell from '../../../Components/EditableTimeSpentCell';
import { useProjectContext } from '../../../Context/ProjectContext';


const TimeSpentColumn = {
  Header: 'Time Spent',
  accessor: 'timeSpent',
  width: 10,
  Cell: ({
    value: initialValue,
    row,
    column
  }) => {
    const { update } = useProjectContext()
    const isSubtask = !!row.original.subtaskId

    const onChange = (newValue) => {
      if (isSubtask) {
        update.subTask(row.original, column.id, newValue)
      } else {
        update.task(row.original, column.id, newValue)
      }
    };

    return (
      <EditableTimeSpentCell
        value={initialValue}
        onChange={onChange}
      />
    );
  },
  className: 'pmt-time-spent-column',
};

export default TimeSpentColumn;

// File Path: src\Modals\TeamMembersModal\MemberComponent.js

import React, { useState } from 'react';
import { List, Button } from 'antd';
import SingleAvatar2 from '../../Components/SingleAvatar2';
import { getFullName } from '../../services/auth.service';

const MemberComponent = ({ project, member, isSuperAdmin, onRemoveMember }) => {
  const [disabled, setDisabled] = useState(false);

  const onRemove = () => {
    setDisabled(true);
    onRemoveMember(project.id, member.user_id).finally(() => {
      setDisabled(false);
    });
  };

  return (
    <List.Item
      actions={
        isSuperAdmin
          ? [
            <Button
              disabled={disabled}
              onClick={onRemove}
              className="remove-button"
              type="link"
              danger
              key="remove"
            >
              Remove
            </Button>,
          ]
          : undefined
      }
    >
      <List.Item.Meta
        avatar={<SingleAvatar2 name={getFullName(member.user)} />}
        title={getFullName(member.user)}
        description={member.member.job_title}
      />
    </List.Item>
  );
};


export default MemberComponent;

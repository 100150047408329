import React, { useState } from "react";
import SubtaskRow from "./SubtaskRow";
import TaskDetailsModal from "../../Modals/TaskDetailsModal/TaskDetailsModal";
import { useProjectContext } from "../../Context/ProjectContext";
import "./WorkPages.less";
import { updateTaskInBulk } from "../../services/project-task.service";
import AddSubTaskButton from "./TableColumns/AddSubTaskButton";
import { useSelector } from "react-redux";
import { getRoleName } from "../../services/auth.service";
import DeleteTaskButton from "./TableColumns/DeteleTaskButton";
import { Tooltip } from "antd";

const ModalButton = ({ index }) => {
  const [visible, setVisible] = useState(false);
  function onOpen() {
    setVisible(true);
  }
  return (
    <>
      <Tooltip title="Task Details">
        <span className="pmt-task-details-icon" onClick={onOpen}>
          <i className="fi fi-rr-arrow-up-right-from-square"></i>
        </span>
      </Tooltip>
      {visible && (
        <TaskDetailsModal
          taskIndex={index}
          visible={visible}
          setVisible={setVisible}
        />
      )}
    </>
  );
};

const TableRow = ({ row, index, prepareRow }) => {
  const user = useSelector((state) => state.auth.user);
  const role = getRoleName(user);
  const { groupByColumns, tasks, setTasks, dragData, setDragData, project } =
    useProjectContext();
  prepareRow(row);
  const [isExpanded, setIsExpended] = useState(false);

  function getClassNames() {
    const classNames = ["pmt-row"];
    if (row.original.subtasks.length === 0) {
      classNames.push("task-without-subtasks");
    }
    if (row.original.taskId === dragData.to) {
      classNames.push("drop-target");
    }
    return classNames.join(" ");
  }

  const onDragStart = (event) => {
    dragData.from = row.original.taskId;
    setDragData({ ...dragData });
  };

  const onDragOver = (event) => {
    event.preventDefault(); // Necessary to allow the drop
    if (dragData.from > -1) {
      dragData.to = row.original.taskId;
      setDragData({ ...dragData });
    }
  };

  const onDrop = (event) => {
    event.preventDefault();
    if (dragData.from > -1) {
      const task = {
        from: tasks.findIndex((item) => item.taskId === dragData.from),
        to: tasks.findIndex((item) => item.taskId === dragData.to),
      };
      if (task.from > -1 && task.to > -1) {
        const dataToPost = { task_ids: [dragData.from] };
        groupByColumns.forEach((item) => {
          tasks[task.from][item] = tasks[task.to][item];
          if (item === "assignee") {
            dataToPost.assignee_id = tasks[task.to][item];
          } else {
            dataToPost[item] = tasks[task.to][item];
          }
        });
        updateTaskInBulk(project.id, dataToPost, role);
        setTasks([...tasks]);
      }
      setDragData({ from: -1, to: -1 });
    }
  };

  return (
    <>
     <tr
  {...row.getRowProps()}
  id={"task-" + row.original.taskId}
  onDrop={onDrop}
  className={getClassNames()}
>
  {row.cells.map((cell) => (
    <td
      onDragOver={onDragOver}
      {...cell.getCellProps()}
      className="pmt-cell"
    >
      {cell.column.id === "taskName" ? (
        <div className="pmt-task-cell-content">
          <span
            className="pmt-drag-handle"
            onDragStart={onDragStart}
            draggable
          >
            <i className="fi fi-ss-grip-dots-vertical" style={{ color: 'lightgray' }}></i>{" "}
            {/* Drag icon */}
          </span>
          <div className="pmt-task-name">
            {row.original.subtasks.length > 0 && (
              <span
                style={{ position: "relative", left: "20px" }}
                className={`pmt-expand-icon ${
                  isExpanded ? "pmt-expanded" : ""
                }`}
                onClick={() => setIsExpended(!isExpanded)}
              >
                {isExpanded ? (
                  <i className="fi fi-sr-caret-down"></i>
                ) : (
                  <i className="fi fi-sr-caret-right"></i>
                )}
              </span>
            )}
            <span
              style={{ position: "relative", left: "20px", width: "85%" }}
            >
              {cell.render("Cell")}
            </span>
          </div>
          {row.original.subtasks.length > 0 && (
            <span className="pmt-subtask-info">
              <i className="fi fi-rr-diagram-subtask"></i>{" "}
              {row.original.subtasks.length}
            </span>
          )}
          <AddSubTaskButton
            taskIndex={row.original.taskIndex}
            isExpanded={isExpanded}
            setIsExpended={setIsExpended}
          />
          <DeleteTaskButton taskIndex={row.original.taskIndex} />
          <ModalButton index={row.original.taskIndex} />
        </div>
      ) : (
        cell.render("Cell")
      )}
    </td>
  ))}
</tr>


      {isExpanded &&
        row.original.subtasks.map((subtask, subIndex) => (
          <SubtaskRow
            key={subtask.subtaskId}
            subtask={subtask}
            taskIndex={index}
            subtaskIndex={subIndex}
            taskId={row.original.taskId}
          />
        ))}
    </>
  );
};

export default TableRow;

import TaskNameColumn from './TaskNameColumn';
import AssigneeColumn from './AssigneeColumn';
import StatusColumn from './StatusColumn';
import DueDateColumn from './DueDateColumn';
import TimeSpentColumn from './TimeSpentColumn';
import TimeAllotedColumn from './TimeAllotedColumn';

const columns = [
  TaskNameColumn,
  AssigneeColumn,
  StatusColumn,
  DueDateColumn,
  TimeAllotedColumn,
  TimeSpentColumn,
];
export const groupByOptions = [{ key: 'section', label: "Section" }, ...(columns.map((item) => {
  const { accessor, Header } = item
  return { key: accessor, label: Header }
}))]

export default columns;

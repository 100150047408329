import React, { useState, useEffect, useRef } from "react";
import { Input } from "antd";
import { useProjectContext } from "../../../Context/ProjectContext";

export const EditableTaskNameCell = ({
  value: initialValue,
  row,
  column,
  taskIndex,
  subtaskIndex,
}) => {
  const [value, setValue] = useState(initialValue);
  const { update, add, remove, focusTaskIdRef } = useProjectContext(); // Reintroduce focusTaskIdRef
  const inputRef = useRef(null);
  const { index } = row;
  const isSubtask = !!row.original.subtaskId;

  // Ensure that the input value is updated when the initial value changes
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  // Re-focus the input when adding a new task/subtask
  useEffect(() => {
    if (focusTaskIdRef.current === row.original.taskId && inputRef.current) {
      inputRef.current.focus();
      focusTaskIdRef.current = null; // Reset focus after focusing
    }
  }, [row.original.taskId, focusTaskIdRef]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    if (isSubtask) {
      update.subTask(row.original, column.id, value);
    } else {
      update.task(row.original, column.id, value);
    }
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter" && !!value) {
      if (isSubtask) {
        const { taskIndex, subTaskIndex } = row.original;
        add.subTask(taskIndex, subTaskIndex);
      } else {
        const { groupIndex, taskIndex } = row.original;
        add.task(groupIndex, taskIndex);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Delete" && value === "") {
      if (isSubtask) {
        remove.subTask(taskIndex, subtaskIndex);
      } else {
        remove.task(index);
      }
    }
  };

  return (
    <Input
      ref={inputRef} // Input reference for focusing
      value={value}
      className={
        isSubtask ? `input-${taskIndex}-${subtaskIndex}` : `input-${index}`
      }
      onChange={onChange}
      onBlur={onBlur}
      onKeyPress={onKeyPress}
      onKeyDown={handleKeyDown}
    />
  );
};

const TaskNameColumn = {
  Header: "Task Name",
  accessor: "taskName",
  width: 50,
  Cell: EditableTaskNameCell,
  className: "pmt-task-column",
};

export default TaskNameColumn;

import React from 'react';
import SingleUserAvatar from '../../../Components/SingleUserAvatar';
import { Col, Row } from 'antd';
import SingleAvatar2 from '../../../Components/SingleAvatar2';

const AssigneeColumn = {
  title: 'Assignee',
  className: 'assignee-avatar',
  render: (text, record) => {
    const assignee = record.assignee
    const manager = record.project.client.manager
    return <>
      {assignee ? <SingleUserAvatar user={assignee} /> : "Not Assigned"}
      {manager ? <>
        
        <div className="lite-text"><b>Account Manager</b></div>
        <div className="lite-text">{manager.f_name + " " + manager.l_name}</div>
      </> : null}
    </>
  },
  responsive: ['lg'],
};

export default AssigneeColumn;

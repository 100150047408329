// File Path: src\Modals\TeamMembersModal\MemberPicker.js

import React, { useState } from 'react';
import { Select, Spin, Row, Col } from 'antd';
import SingleAvatar2 from '../../Components/SingleAvatar2';
import { memberAutocomplete } from '../../services/member.service';

const { Option } = Select;

const MemberPicker = ({ placeholder, onChange }) => {
  const [options, setOptions] = useState([]);
  const [fetching, setFetching] = useState(false);

  const handleSearch = (searchValue) => {
    if (searchValue.length >= 2) {
      setFetching(true);
      memberAutocomplete(searchValue)
        .then((response) => {
          setOptions(response.data);
          setFetching(false);
        })
        .catch(() => {
          setFetching(false);
        });
    }
  };

  const handleChange = (newValue) => {
    const newSelected = options.find(option => option.id.toString() === newValue) || null;
    setOptions([]);
    onChange(newSelected);
  };

  return (
    <Select
      style={{ width: '100%' }}
      showSearch
      placeholder={placeholder}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      optionLabelProp="label"
    >
      {options.map(option => (
        <Option key={option.id} value={option.id.toString()} label={`${option.f_name} ${option.l_name}`}>
          <Row gutter={5} align="middle">
            <Col span={2}>
              <SingleAvatar2 name={`${option.f_name} ${option.l_name}`} />
            </Col>
            <Col span={5}>{`${option.f_name} ${option.l_name}`}</Col>
          </Row>
        </Option>
      ))}
    </Select>
  );
};


export default MemberPicker;

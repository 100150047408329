import React, { useState, useEffect, useRef } from 'react';
import { Input, Button, Skeleton } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './Searchbar.less';
import { companySearch, superAdminSearch } from '../services/search.service';
import { useSelector } from 'react-redux';
import { hasRole } from '../services/auth.service';
import { Link } from 'react-router-dom';
import { appSubDir } from '../config';

const { Search } = Input;

const getIcon = (type) => {
  switch (type) {
    case 'Client': return <i className="fi fi-rr-users-alt"></i>;
    case 'Project': return <i className="fi fi-rr-briefcase"></i>;
    case 'Task': return <i className="fi fi-rr-list-check"></i>;
    case 'Comment': return <i className="fi fi-rr-comment-alt"></i>;
    case 'Team Member': return <i className="fi fi-rr-user"></i>;
    default: return null;
  }
};

const truncateText = (text, maxLength) => {
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
};

const hasData = (response) => {
  return response && (
    response.clients.length ||
    response.projects.length ||
    response.projectTasks.length ||
    response.projectTaskComments.length ||
    response.teamMembers.length
  );
};

const renderResults = (type, data, onResultClick) => {
  if (!data || data.length === 0) return null;

  let items = [];

  switch (type) {
    case 'Client':
      items = data.map(item => (
        <div key={item.id} className={`searchbar-result-item searchbar-result-category-${type}`}>
          <span className="result-icon">{getIcon(type)}</span>
          {/* <span className="result-name">{truncateText(item.company_name, 100)}</span> */}
          
          <Link to={appSubDir+`projects/${item.id}`} className="result-name search-link" onClick={onResultClick}>
            {truncateText(item.company_name, 100)}
          </Link>
          <span className="result-type">{type}</span>
        </div>
      ));
      break;
    case 'Project':
      items = data.map(item => (
        <div key={item.id} className={`searchbar-result-item searchbar-result-category-${type}`}>
          <span className="result-icon">{getIcon(type)}</span>
          <Link to={appSubDir+`projects/${item.id}`} className="result-name search-link" onClick={onResultClick}>
            {truncateText(item.name, 100)}
          </Link>
          <span className="result-type">{type}</span>
        </div>
      ));
      break;
    case 'Task':
      items = data.map(item => (
        <div key={item.task_id} className={`searchbar-result-item searchbar-result-category-${type}`}>
          <span className="result-icon">{getIcon(type)}</span>
          <span className="result-name">{truncateText(item.task, 100)}</span>
          <span className="result-type">{type}</span>
        </div>
      ));
      break;
    case 'Comment':
      items = data.map(item => (
        <div key={item.id} className={`searchbar-result-item searchbar-result-category-${type}`}>
          <span className="result-icon">{getIcon(type)}</span>
          <span className="result-name">{truncateText(item.comment, 100)}</span>
          <span className="result-type">{type}</span>
        </div>
      ));
      break;
    case 'Team Member':
      items = data.map(item => (
        <div key={item.id} className={`searchbar-result-item searchbar-result-category-${type}`}>
          <span className="result-icon">{getIcon(type)}</span>
          <span className="result-name">{truncateText(item.f_name + " " + item.l_name, 100)}</span>
          <span className="result-type">{type}</span>
        </div>
      ));
      break;
    default:
      break;
  }

  return (
    <>
      {items.length > 0 && (
        <div className="searchbar-category-header">
          <div className="search-icon-container">
            {getIcon(type)}
          </div>
          <span>{type}s</span>
        </div>
      )}
      {items}
    </>
  );
};

const SearchbarInput = () => {
  const user = useSelector((state) => state.auth.user);
  const isClient = hasRole(user, 'client');
  const [searchQuery, setSearchQuery] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState(['All']);
  const [loading, setLoading] = useState(false);
  const searchContainerRef = useRef(null);
  const timeoutRef = useRef(null);

  const handleResultClick = () => {
    setShowResults(false);
  };

  const handleSearch = () => {
    setLoading(true);
    const apiCall = isClient ? companySearch : superAdminSearch;
    apiCall(searchQuery).then((response) => {
      setApiResponse(response.data);
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (value.length >= 2) {
      setShowResults(true);
      timeoutRef.current = setTimeout(handleSearch, 500);
    } else {
      setShowResults(false);
      setApiResponse(null);
    }
  };

  const handleClickOutside = (event) => {
    if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
      setShowResults(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCategoryClick = (category) => {
    if (category === 'All') {
      setSelectedCategories(['All']);
    } else {
      setSelectedCategories((prevCategories) => {
        if (prevCategories.includes(category)) {
          const newCategories = prevCategories.filter((cat) => cat !== category);
          return newCategories.length > 0 ? newCategories : ['All'];
        } else {
          return [...prevCategories.filter((cat) => cat !== 'All'), category];
        }
      });
    }
  };

  const isCategorySelected = (category) => selectedCategories.includes(category);

  const tabItems = isClient ? ['All', 'Project', 'Task'] : ['All', 'Client', 'Project', 'Task', 'Comment', 'Team Member'];

    return(
      <div className="search-input-container" ref={searchContainerRef}>
        <Search
          placeholder="Search..."
          enterButton={<SearchOutlined />}
          size="large"
          className="search-input"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        {showResults && (
          <div className="searchbar-results-container">
            <div className="filter-categories">
              <i className="fi fi-rr-filter"></i>
              <span>Filter Categories</span>
            </div>
            <div className="searchbar-category-buttons">
              {tabItems.map(category => (
                <Button
                  key={category}
                  className={`searchbar-category-button ${isCategorySelected(category) ? 'active' : ''}`}
                  onClick={() => handleCategoryClick(category)}
                >
                  {category}s
                </Button>
              ))}
            </div>
            <div className="searchbar-results">
              {loading ? (
                <>
                  <Skeleton active />
                  <Skeleton active />
                  <Skeleton active />
                </>
              ) : hasData(apiResponse) ? (
                <>
                  {selectedCategories.includes('All') || selectedCategories.includes('Client') ? renderResults('Client', apiResponse.clients, handleResultClick) : null}
                  {selectedCategories.includes('All') || selectedCategories.includes('Project') ? renderResults('Project', apiResponse.projects, handleResultClick) : null}
                  {selectedCategories.includes('All') || selectedCategories.includes('Task') ? renderResults('Task', apiResponse.projectTasks, handleResultClick) : null}
                  {selectedCategories.includes('All') || selectedCategories.includes('Comment') ? renderResults('Comment', apiResponse.projectTaskComments, handleResultClick) : null}
                  {selectedCategories.includes('All') || selectedCategories.includes('Team Member') ? renderResults('Team Member', apiResponse.teamMembers, handleResultClick) : null}
                </>
              ) : (
                <div className="searchbar-no-results">No results found</div>
              )}
            </div>
          </div>
        )}
      </div>
    );
};

export default SearchbarInput;

import axios from "axios";
import { apiBaseUrl } from "../config";
import { getAuthHeader } from "./auth.service";
export function fetchTopNotifications() {
    return axios.get(apiBaseUrl + "notifications/top", getAuthHeader())
}
export function fetchNotifications(page = 0, rowsPerPage = 20, sortByColumns = []) {
    const query = new URLSearchParams("")
    if (page) {
        query.set('page', page.toString());
    }
    if (rowsPerPage) {
        query.set('per_page', rowsPerPage.toString());
    }
    if (sortByColumns && sortByColumns.length > 0) {
        sortByColumns.forEach(element => {
            if (element.key && element.order)
                query.set(element.key, element.order);
        });
    }
    let url = [apiBaseUrl + "notifications", query.toString()].join("/?")
    return axios.get(url, getAuthHeader())
}
export function fetchNotification(notificationId) {
    return axios.get(apiBaseUrl + `notifications/${notificationId}`, getAuthHeader())
}
export function updateNotification(notificationId, values) {
    return axios.put(apiBaseUrl + `notifications/${notificationId}`, values, getAuthHeader())
}
export function removeMember(notificationId, user_id) {
    return axios.delete(apiBaseUrl + `notifications/${notificationId}/members/${user_id}`, getAuthHeader())
}
export function addMember(notificationId, user_id) {
    return axios.post(apiBaseUrl + `notifications/${notificationId}/member`, { user_id }, getAuthHeader())
}
export function addNotification(name, client_id) {
    return axios.post(apiBaseUrl + `notifications`, { name, client_id }, getAuthHeader())
}

export function createNotification(values) {
    return axios.post(apiBaseUrl + 'notifications', values, getAuthHeader())
}

export function notificationAutocomplete(value) {
    return axios.get(apiBaseUrl + `notifications/autocomplete/${value}`, getAuthHeader())
}

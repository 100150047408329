// File Path : src\services\project-task.service.js

import axios from "axios";
import { apiBaseUrl } from "../config";
import { getApiPrefix, getAuthHeader } from "./auth.service";
export function fetchTasks(projectId, role = 'super-admin') {
    return axios.get(apiBaseUrl + getApiPrefix(role) + `projects/${projectId}/tasks`, getAuthHeader())
        .then((res) => {
            const tasks = res.data
            tasks.forEach((item, index) => {
                item.taskId = item.task_id
                item.position = index
                item.taskName = item.task
                item.dueDate = item.due_date
                item.assignee = item.assignee_id
                item.hours_allotted = parseFloat(item.hours_allotted) || 0
                item.timeSpent = parseFloat(item.hours_tracked) || 0
                item.subtasks.forEach((subTask, si) => {
                    subTask.subtaskId = subTask.sub_task_id
                    subTask.position = si
                    subTask.taskName = subTask.task
                    subTask.dueDate = subTask.due_date
                    subTask.assignee = subTask.assignee_id
                    subTask.timeSpent = parseFloat(subTask.hours_tracked) || 0
                })
            })
            return tasks
        })
}
export function upsertTask(
    action,
    projectId,
    task_id,
    sub_task_id,
    task,
    assignee_id,
    due_date,
    hours_tracked,
    status,
    hours_allotted,
    start_date,
    section,
    description,
    role
) {
    const apiCall = (action === "add") ? axios.post : axios.put
    const dataToSend = {
        sub_task_id,
        task_id,
        task,
        assignee_id,
        due_date,
        hours_tracked,
        status,
        hours_allotted,
        start_date,
        section,
        description
    }
    const url = apiBaseUrl + getApiPrefix(role) + `projects/${projectId}/tasks/${action === "add" ? "" : task_id}`
    return apiCall(url, dataToSend, getAuthHeader())
}
export function removeMember(projectId, user_id) {
    return axios.delete(apiBaseUrl + `projects/${projectId}/members/${user_id}`, getAuthHeader())
}
export function addMember(projectId, user_id) {
    return axios.post(apiBaseUrl + `projects/${projectId}/member`, { user_id }, getAuthHeader())
}
export function updateTaskInBulk(projectId, dataToPut, role) {
    return axios.put(apiBaseUrl + getApiPrefix(role) + `projects/${projectId}/tasks-in-bulk/`, dataToPut, getAuthHeader())
}

export function createTask(values) {
    return axios.post(apiBaseUrl + 'projects', values, getAuthHeader())
}

// *api for delete task
export function deleteTask(projectId, taskId, role) {
    return axios.delete(apiBaseUrl + getApiPrefix(role) + `projects/${projectId}/tasks/${taskId}`, getAuthHeader())
}
export function deleteSubTask(projectId, taskId,subTaskId, role) {
    return axios.delete(apiBaseUrl + getApiPrefix(role) + `projects/${projectId}/tasks/${taskId}/sub_tasks/${subTaskId}`, getAuthHeader())
}

export function projectAutocomplete(value) {
    return axios.get(apiBaseUrl + `projects/autocomplete/${value}`, getAuthHeader())
}
export class ProjectTaskDocumentService {
    constructor(projectId, taskId, role) {
        this.projectId = projectId
        this.taskId = taskId
        this.role = role
        this.headers = getAuthHeader()
    }
    index() {
        return axios.get(apiBaseUrl + getApiPrefix(this.role)+ `projects/${this.projectId}/tasks/${this.taskId}/documents`, this.headers)
    }
    store(dataToPost) {
        return axios.post(apiBaseUrl+ getApiPrefix(this.role) + `projects/${this.projectId}/tasks/${this.taskId}/documents`, dataToPost, this.headers)
    }
    delete(documentId) {
        return axios.delete(apiBaseUrl+ getApiPrefix(this.role) + `projects/${this.projectId}/tasks/${this.taskId}/documents/${documentId}`, this.headers)
    }
}
export class ProjectTaskCommentService {
    constructor(projectId, taskId, role) {
        this.projectId = projectId
        this.taskId = taskId
        this.role = role
        this.headers = getAuthHeader()
    }
    index() {
        return axios.get(apiBaseUrl + getApiPrefix(this.role)+ `projects/${this.projectId}/tasks/${this.taskId}/comments`, this.headers)
    }
    store(dataToPost) {
        return axios.post(apiBaseUrl + getApiPrefix(this.role)+ `projects/${this.projectId}/tasks/${this.taskId}/comments`, dataToPost, this.headers)
    }
    delete(commentId) {
        return axios.delete(apiBaseUrl+ getApiPrefix(this.role) + `projects/${this.projectId}/tasks/${this.taskId}/comments/${commentId}`, this.headers)
    }
}

import React, { useEffect, useState } from 'react';
import { Carousel, Menu, Dropdown } from 'antd';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import projectsData from '../../Data/SampleProjects'; // Ensure this path is correct
import './Dashboard.less'; // Import the CSS file
import getStatusTag from '../../Components/StatusTags2'; // Import the status tag function
import DashboardProjectColors from './DashboardProjectColors'; // Import the DashboardProjectColors component
import { HoursBar, TasksBar } from './DashboardProjectBarChart'; // Import the separate charts
import { fetchProjects } from '../../services/project.service';
import { Link } from 'react-router-dom';
import { appSubDir } from '../../config';
import { getRoleName } from '../../services/auth.service';
import { useSelector } from 'react-redux';

const DashboardProjects = () => {
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate()
  const [projectsData, setProjects] = useState([]); // Initialize the projects state
  const [loading, setLoading] = useState(true)
  const chunkedProjects = [];
  for (let i = 0; i < projectsData.length; i += 4) {
    chunkedProjects.push(projectsData.slice(i, i + 4));
  }
  function loadData() {
    setLoading(true)
    fetchProjects(0, 12, [{ key: 'date', order: "desc" }], getRoleName(user)).then((response) => {
      const newProjects = response.data.data.map((item) => ({
        id: item.id,
        name: item.name,
        projectStatus: item.status,
        tasksOverview: {
          total: item.total_tasks,
          notStarted: item.tasks_not_started,
          inProgress: item.tasks_in_progress,
          completed: item.tasks_completed
        },
        startDate: item.start_date,
        endDate: item.end_date,
        estimatedHours: 500,
        usedHours: 300,
      }))
      setProjects(newProjects);
    }).catch(error => {
      console.error("Fetch Dashboad Projects Failed", error);
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      loadData()
    }, 1)
    return () => {
      clearTimeout(timer)
    }
  }, []);
  if (chunkedProjects.length < 1) {
    return null
  }
  const calculateDaysLeft = (endDate) => {
    const today = new Date();
    const end = new Date(endDate);
    const timeDiff = end - today;
    const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysLeft >= 0 ? `${daysLeft} Days Left` : 'Time Over';
  };


  return (
    <div className="db-project-main-container">
      <Carousel dots arrows>
        {chunkedProjects.map((chunk, index) => (
          <div className="db-project-carousel-container" key={index}>
            <div className="db-projects-title-row">
              <div className="db-carousel-title"> Priority Projects </div>
              <div className="db-view-projects-button" onClick={() => navigate('/projects')}>
                All Projects
                <div className="db-view-projects-button-icon-container">
                  <i className="fi fi-rr-angle-small-right"></i>
                </div>
              </div>
            </div>
            <div className="db-carousel-background">
              {chunk.map((project, idx) => (
                <div key={idx} className="db-project-carousel-item">
                  <div className="db-project-carousel-topbar">
                    {getStatusTag(project.projectStatus)}
                    <p className="db-days-left">{calculateDaysLeft(project.endDate)}</p>
                  </div>
                  <div
                    className="db-project-name-container"
                    style={{
                      backgroundColor: DashboardProjectColors({ projectName: project.name }).props.style.backgroundColor,
                    }}
                  >
                    <div className="db-project-name">
                      {project.name}
                      <Dropdown dropdownRender={() => (<Menu>
                        <Link to={appSubDir + "projects/" + project.id}><Menu.Item key="1">View Project</Menu.Item></Link>
                      </Menu>)} trigger={['click']} placement="bottomRight">
                        <div className="db-project-menu-icon">
                          <i className="fi fi-br-menu-dots"></i>
                        </div>
                      </Dropdown>
                    </div>
                  </div>

                  <div className="db-project-barchart-container">
                    <HoursBar project={project} />
                  </div>

                  <div className="db-project-barchart-container">
                    <TasksBar project={project} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default DashboardProjects;

import React, { useState } from 'react';
import { Button } from 'antd';
import TextEditor2 from '../../Components/TextEditor2'; // Import the updated TextEditor component
import '../../Components/Components.less';
import '../../custom.less';

const AddTaskDescription = ({task,onTaskUpdate}) => {
  const [description, setDescription] = useState(task.description||"");
  const [postedDescription, setPostedDescription] = useState(task.description);
  const [editorState, setEditorState] = useState(true);

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  const handlePost = () => {
    setPostedDescription(description);
    setDescription('');
    setEditorState(false); // Change to posted state
    onTaskUpdate('description',description)
  };

  const handleEdit = () => {
    setDescription(postedDescription);
    setPostedDescription(null);
    setEditorState(true); // Change to editor state for editing
  };

  return (
    <div className="task-details-add-description-container">
      {postedDescription ? (
        <div className="task-details-posted-description">
          <div className="task-details-edit-button-container">
            <button onClick={handleEdit} className="task-details-edit-button">
              Edit Description
            </button>
          </div>
          <div className="task-details-posted-description-content" dangerouslySetInnerHTML={{ __html: postedDescription }} />
        </div>
      ) : (
        <div className="task-details-description-editor">
          <TextEditor2
            value={description}
            onChange={handleDescriptionChange}
            className="task-details-description-textarea"
          />
          <Button onClick={handlePost} type="secondary" className="task-details-post-button">
            Post
          </Button>
        </div>
      )}
    </div>
  );
};

export default AddTaskDescription;

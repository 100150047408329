import React, { useEffect, useRef, useState } from 'react';
import { Input, Button, Avatar, List, Popconfirm, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { ProjectTaskCommentService } from '../../services/project-task.service';
import { useProjectContext } from '../../Context/ProjectContext';
import { dateTimeFormat, truncateWithEllipsis } from '../../services/common.functions';
import { useSelector } from 'react-redux';
import { getRoleName } from '../../services/auth.service';

const { TextArea } = Input;

const TaskComments = ({ taskIndex }) => {
  const user = useSelector((state) => state.auth.user);
  const role = getRoleName(user);
  const textareaRef = useRef(null)
  const [commentList, setCommentList] = useState([]);
  const [commentText, setCommentText] = useState('');
  const { tasks, project } = useProjectContext()
  const task = tasks[taskIndex]
  const cs = new ProjectTaskCommentService(project.id, task.task_id, role)
  useEffect(() => {
    const timer = setTimeout(() => {
      cs.index().then((response) => {
        console.log(response.data)
        setCommentList(response.data)
      })
    }, 10)
    return () => {
      clearTimeout(timer, project)
    }
  }, [task.task_id])

  const handlePost = () => {
    if (commentText.length > 0) {
      cs.store({ comment: commentText }).then((response) => {
        setCommentText('');  // Clear the textarea after submitting
        setCommentList(response.data);
      });
    }
  };
  const handleDelete = (item, index) => {
    commentList.splice(index, 1)
    setCommentList([...commentList])

    cs.delete(item.comment_id)
      .then(() => {
        commentList.slice(index, 1)
        setCommentList([...commentList])
        message.success(`"${truncateWithEllipsis(item.comment, 30)}" deleted successfully`);
      })
      .catch((error) => {
        message.error(`Failed to delete ${item.file_name}: ${error.message}`);
      });
  };

  return (
    <div className="tdm-task-comments">
      <h3 className="tdm-comments-title">Comments</h3>
      {commentList.length > 0 && <List
        itemLayout="horizontal"
        className="tdm-comments-list"
        dataSource={commentList}
        renderItem={(item, index) => (
          <List.Item
            actions={[
              <Popconfirm
                title={`Are you sure you want to delete ?`}
                onConfirm={() => handleDelete(item, index)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link" danger icon={<DeleteOutlined />}>
                  Delete
                </Button>
              </Popconfirm>,
            ]}>
            <List.Item.Meta
              avatar={<Avatar>{[item.user.f_name, item.user.l_name].map(n => n[0]).join('')}</Avatar>}
              title={<span>{[item.user.f_name, item.user.l_name].join(' ')} <span className="tdm-message-date"> {dateTimeFormat.short(item.date)}</span></span>}
              description={<span className="tdm-comment-message">{item.comment}</span>}
            />
          </List.Item>
        )}
      />}
      <TextArea
        ref={textareaRef}
        value={commentText}  // Use the state value
        onChange={(e) => setCommentText(e.target.value)}  // Update state on input change
        rows={4}
        className="tdm-comments-textarea"
        placeholder="Type / for menu"
      />
      <Button type="secondary" onClick={handlePost} className="tdm-comments-button">
        Comment
      </Button>
    </div>
  );
};

export default TaskComments;

import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { hasRole } from '../services/auth.service';
import './Sidebar.less';

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = ({ isSidebarVisible }) => {
  const user = useSelector((state) => state.auth.user);
  const isSuperAdmin = hasRole(user, 'super-admin')
  const isTeam = hasRole(user, 'team')
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();

  const rootSubmenuKeys = ['/work'];

  useEffect(() => {
    const path = location.pathname.split('/');
    if (path.length > 1) {
      const matchedKey = rootSubmenuKeys.find(key => location.pathname.includes(key));
      if (matchedKey) {
        setOpenKeys([matchedKey]);
      }
    }
  }, [location.pathname]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <Sider
      width={240}
      className="site-layout-background sidebar"
      style={{
        height: '100vh',
        position: 'fixed',
        left: isSidebarVisible ? 0 : -240,
        top: 0,
        bottom: 0,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        transition: 'left 0.3s',
      }}
      collapsedWidth="0"
    >
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', paddingTop: '94px' }}>
        <Menu
          mode="inline"
          selectedKeys={[location.pathname]}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          style={{ flex: 1, borderRight: 0 }}
          className="aio-main-menu"
        >

          <Menu.Item key="/">
            <NavLink to="/" activeclassname="ant-menu-item-selected" style={{ display: 'flex', alignItems: 'center' }}>
              <i className="fi fi-rr-objects-column" ></i>
              <span>Dashboard</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="/clients">
            <NavLink to="/clients" activeclassname="ant-menu-item-selected">
              <i className="fi fi-rr-building" ></i>
              <span>Clients</span>
            </NavLink>
          </Menu.Item>
          <SubMenu key="/work" title="Work" icon={<i className="fi fi-rr-briefcase" ></i>}>
            <Menu.Item key="/projects">
              <NavLink to="/projects" activeclassname="ant-menu-item-selected">
                Projects
              </NavLink>
            </Menu.Item>
            <Menu.Item key="/work/tasks">
              <NavLink to="/work/tasks" activeclassname="ant-menu-item-selected">
                Tasks
              </NavLink>
            </Menu.Item>
            {isSuperAdmin && <Menu.Item key="/team-overview">
              <NavLink to="/team-overview" activeclassname="ant-menu-item-selected">
                Team Overview
              </NavLink>
            </Menu.Item>}
            {!isSuperAdmin && <Menu.Item key="/work/hours-tracker">
              <NavLink to="/work/hours-tracker" activeclassname="ant-menu-item-selected">
                Hours Tracker
              </NavLink>
            </Menu.Item>}
          </SubMenu>
          {!isTeam && <Menu.Item key="/automations">
            <NavLink to="/automations" activeclassname="ant-menu-item-selected">
              <i className="fi fi-rr-diagram-project" ></i>
              <span>Automations</span>
            </NavLink>
          </Menu.Item>}
          {isSuperAdmin && <SubMenu key="/billing" title="Billing" icon={<i className="fi fi-rr-money" ></i>}>
            <Menu.Item key="/billing/subscriptions">
              <NavLink to="/billing/subscriptions" activeclassname="ant-menu-item-selected">
                Subscriptions
              </NavLink>
            </Menu.Item>
            <Menu.Item key="/billing/trasactions">
              <NavLink to="/billing/trasactions" activeclassname="ant-menu-item-selected">
                Transactions
              </NavLink>
            </Menu.Item>
          </SubMenu>}
        </Menu>
      </div>
    </Sider>
  );
};

export default Sidebar;

// src/Utils/taskActions.js

import { enhanceElementAppearance } from "../services/common.functions";
import { upsertTask } from "../services/project-task.service";
import { sortItemsAction } from "./SortingActions";

/**
 * Sorts the tasks array based on given sorting conditions.
 *
 * @param {Array} tasks - The array of task objects to be sorted.
 * @param {Array} conditions - The sorting conditions array, e.g.,
 * [{ column: "taskName", order: "asc" }, ...]
 */
export function sortTaskAction(tasks, conditions) {
  if (conditions.length) {
    sortItemsAction(tasks, conditions);
  } else {
    sortItemsAction(tasks, [
      {
        column: "position",
        order: "asc",
      },
    ]);
  }
}

/**
 * Groups tasks based on specified keys.
 *
 * @param {Array} tasks - The array of task objects to be grouped.
 * @param {Array} keys - The keys to group tasks by, e.g., ['assignee', 'status'].
 * @returns {Array} An array of grouped tasks.
 */
// Example Column Keys:
// const keys = ['assignee', 'status'];
export function groupTasksAction(tasks, keys) {
  if (keys.length < 1) {
    return [];
  }

  // Helper function to generate unique key for grouping
  function generateKey(task) {
    return keys.map((key) => `${key}:${task[key]}`).join("|");
  }

  // Dictionary to store unique groupings
  const groups = {};

  // Process each task
  tasks.forEach((task) => {
    const groupKey = generateKey(task);

    if (!groups[groupKey]) {
      // Initialize the group if it doesn't exist
      groups[groupKey] = {
        attributes: {},
        tasks: [],
      };

      // Set attributes
      keys.forEach((key) => {
        groups[groupKey].attributes[key] = task[key];
      });
    }

    // Add the task to the correct group
    groups[groupKey].tasks.push(task);
  });

  // Convert dictionary to array format as requested
  const newGroups = Object.values(groups);
  if (newGroups.length > 0) {
    newGroups.forEach((group, groupIndex) => {
      group.tasks.forEach((task, taskIndex) => {
        task.groupIndex = groupIndex;
        task.groupTaskIndex = taskIndex;
        task.subtasks.forEach((subTask, subTaskIndex) => {
          subTask.groupIndex = groupIndex;
          subTask.groupTaskIndex = taskIndex;
          subTask.groupSubTaskIndex = subTaskIndex;
        });
      });
    });
  }
  return newGroups;
}

/**
 * Updates task and subtask indices before setting tasks.
 *
 * @param {Array} tasks - The array of tasks to update.
 */
export function beforeSetTasks(tasks) {
  tasks.forEach((task, taskIndex) => {
    task.taskIndex = taskIndex;
    task.subtasks.forEach((subTask, subTaskIndex) => {
      subTask.taskIndex = taskIndex;
      subTask.subTaskIndex = subTaskIndex;
    });
  });
}
export function getGroupIndex(taskGroups, taskId) {
  return taskGroups.findIndex((item) =>
    item.tasks.map((item) => item.taskId).includes(taskId)
  );
}
export function getGroupIndexFromSubtask(
  taskGroups,
  taskIndex,
  subtaskIndex,
  subtaskId
) {
  return taskGroups.findIndex((item) => {
    const task = item.tasks[taskIndex];
    if (!task) {
      return false;
    }
    const subTask = task.subtasks[subtaskIndex];
    if (!subTask || subTask.subtaskId !== subtaskId) {
      return false;
    }
    return true;
  });
}
export function updateTaskAction(
  tasks,
  taskIndex,
  updatedTask,
  conditions,
  role
) {
  const task = tasks[taskIndex];
  tasks[taskIndex] = { ...task, ...updatedTask };
  const newTask = tasks[taskIndex];
  upsertTask(
    newTask.notInserted ? "add" : "update",
    newTask.project_id,
    newTask.task_id,
    0,
    newTask.taskName,
    newTask.assignee || null,
    newTask.dueDate,
    newTask.timeSpent,
    newTask.status,
    newTask.hours_allotted,
    newTask.start_date,
    newTask.section,
    newTask.description,
    role
  );
  if (newTask.notInserted) {
    delete newTask.notInserted;
  }
  sortTaskAction(tasks, conditions);
}

export const updateSubtask = (
  tasks,
  taskIndex,
  subtaskIndex,
  updatedSubtask,
  role
) => {
  const subtask = tasks[taskIndex].subtasks[subtaskIndex];
  tasks[taskIndex].subtasks[subtaskIndex] = { ...subtask, ...updatedSubtask };
  const tsk = tasks[taskIndex];
  const newSubTask = tasks[taskIndex].subtasks[subtaskIndex];
  upsertTask(
    "update",
    tsk.project_id,
    tsk.task_id,
    newSubTask.subtaskId,
    newSubTask.taskName,
    newSubTask.assignee || null,
    newSubTask.dueDate,
    newSubTask.timeSpent,
    newSubTask.status,
    newSubTask.hours_allotted,
    null,
    null,
    null,
    role
  );
  return [...tasks];
};

/**
 * Adds a new subtask to a task.
 *
 * @param {Array} tasks - The array of tasks.
 * @param {number} taskId - The ID of the task to add a subtask to.
 * @returns {Array} The updated tasks array.
 */
export const addNewSubtask = (tasks, taskId) => {
  const newSubtask = {
    subtaskId: new Date().getTime(), // Unique ID for the new subtask
    taskName: "",
    assignee: "",
    status: "Not Started",
    dueDate: null,
    timeSpent: 0,
  };

  return tasks.map((task) =>
    task.taskId === taskId
      ? { ...task, subtasks: [...task.subtasks, newSubtask] }
      : task
  );
};
export const glowEffect = (
  selector,
  focusInput = false,
  startAfter = 10,
  stopAfter = 2000
) => {
  setTimeout(() => {
    const element = document.querySelector(selector);
    // console.log("New Input", element)
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      element.classList.add("glow-effect");
      focusInput && element.querySelector(`input`)?.focus();

      setTimeout(() => {
        element.classList.remove("glow-effect");
      }, stopAfter);
    }
  }, startAfter);
};

/**
 * Scrolls to an element and focuses an input within it.
 *
 * @param {string} selector - The CSS selector of the element.
 * @param {number} [startAfter=10] - Delay before executing in milliseconds.
 */
export const focusRowInput = (selector, startAfter = 10) => {
  setTimeout(() => {
    const element = document.querySelector(selector);
    // console.log("New Input", element)
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      element.querySelector(`input`)?.focus();
    }
  }, startAfter);
};
export const foccusTaskRow = (taskId, startAfter = 10) => {
  enhanceElementAppearance(`#task-${taskId}`, {
    focusInput: false,
    glow: true,
    startAfter,
    stopAfter: 2000,
  });
};
export const foccusTaskInput = (taskId, startAfter = 10) => {
  enhanceElementAppearance(`#task-${taskId}`, { focusInput: true, startAfter });
};
export const foccusSubTaskInput = (taskId, subTaskId) => {
  enhanceElementAppearance(`#task-${taskId}-sub-task-${subTaskId}`, {
    focusInput: true,
  });
};

export const addTaskAction = (
  projectId,
  tasks,
  taskGroups,
  groupIndex,
  groupByColumns,
  taskIndex
) => {
  const taskId =
    tasks.length > 0 ? Math.max(...tasks.map((i) => i.taskId)) + 1 : 1;
  const subtaskId = 0;
  const newTask = {
    taskId,
    project_id: projectId,
    subtaskId,
    task_id: taskId,
    position: tasks.length,
    taskName: "",
    subtasks: [],
    assignee: "",
    status: "Not Started",
    dueDate: null,
    timeSpent: 0,
    notInserted: true, // Not inserted by api
  };
  const group = groupIndex >= 0 ? taskGroups[groupIndex] : null;
  const tableTasks = group ? group.tasks : tasks;
  groupByColumns.forEach((item) => {
    newTask[item] =
      tableTasks.length > 0 ? tableTasks[tableTasks.length - 1][item] : "";
  });
  if (taskIndex >= 0) {
    tasks.splice(taskIndex + 1, 0, newTask);
    tasks.map((item, index) => {
      item.position = index + 1;
      return item;
    });
  } else if (group) {
    const groupLastTask = group.tasks[group.tasks.length - 1]
    tasks.splice(groupLastTask.taskIndex + 1, 0, newTask);
    tasks.map((item, index) => {
      item.position = index + 1;
      return item;
    });
  } else {
    tasks.push(newTask);
  }
  return newTask;
  // sortTaskAction(tasks, sortingConditions)
};

/**
 * Adds a new section task to the tasks array.
 *
 * @param {number} projectId - The ID of the project.
 * @param {Array} tasks - The array of tasks.
 * @param {string} section - The name of the section.
 * @returns {Object} The newly added section task.
 */
export const addSectionAction = (projectId, tasks, section) => {
  const taskId =
    tasks.length > 0 ? Math.max(...tasks.map((i) => i.taskId)) + 1 : 1;
  const subtaskId = 0;
  const newTask = {
    taskId,
    project_id: projectId,
    subtaskId,
    task_id: taskId,
    position: tasks.length,
    taskName: "",
    subtasks: [],
    assignee: "",
    status: "Not Started",
    dueDate: null,
    timeSpent: 0,
    notInserted: true, // Not inserted by api
  };
  newTask.section = section;
  tasks.push(newTask);
  return newTask;
  // sortTaskAction(tasks, sortingConditions)
};

export const addSubTaskAction = (
  projectId,
  tasks,
  taskIndex,
  subtaskIndex,
  role
) => {
  // console.log("subTask :",taskIndex)
  // return
  const task = tasks[taskIndex];
  const subTasks = task.subtasks;
  const subTaskId =
    subTasks.length > 0 ? Math.max(...subTasks.map((i) => i.subtaskId)) + 1 : 1;
  const newSubTask = {
    subTaskId,
    project_id: projectId,
    subtaskId: subTaskId,
    task_id: task.task_id,
    sub_task_id: subTaskId,
    position: subTasks.length,
    taskName: "",
    assignee: "",
    status: "Not Started",
    dueDate: null,
    timeSpent: 0,
    notInserted: true, // Not inserted by api
  };
  // console.log("subTask :", subTaskId)
  // return
  upsertTask(
    "add",
    projectId,
    newSubTask.task_id,
    subTaskId,
    newSubTask.taskName,
    newSubTask.assignee || null,
    newSubTask.dueDate,
    newSubTask.timeSpent,
    newSubTask.status,
    newSubTask.hours_allotted,
    null,
    null,
    null,
    role
  );
  if (subtaskIndex >= 0) {
    subTasks.splice(subtaskIndex + 1, 0, newSubTask);
    subTasks.map((item, index) => {
      item.position = subtaskIndex + 1;
      return item;
    });
  } else {
    subTasks.push(newSubTask);
  }
  return newSubTask;
  // sortTaskAction(tasks, sortingConditions)
};

import React from "react";
import { Button, Dropdown, Menu, message, Popconfirm, Tooltip } from "antd";
import { useProjectContext } from "../../../Context/ProjectContext";
import { DeleteOutlined } from "@ant-design/icons";

const DeleteTaskButton = ({ taskIndex }) => {
  const { remove } = useProjectContext();

  // Function to handle the deletion
  const handleDeleteTask = () => {
    remove.task(taskIndex);
    message.success("Task deleted successfully");
  };

  // Menu for dropdown options
  const menu = (
    <Menu>
      <Menu.Item key="delete">
        <Popconfirm
          title="Are you sure you want to delete this task?"
          onConfirm={handleDeleteTask}
          okText="Yes"
          cancelText="No"
        >
          <Button
            style={{ padding: 0 }}
            type="link"
            danger
            icon={<DeleteOutlined />}
          >
            Delete
          </Button>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  return (
    <Tooltip title="Settings">
      <Dropdown overlay={menu} trigger={["click"]}>
        <Button type="text" className="pmt-task-details-icon">
          <i className="fi fi-rr-settings"></i>
        </Button>
      </Dropdown>
    </Tooltip>
  );
};

export default DeleteTaskButton;

// File Path: src\Modals\TeamMembersModal\AddUserSection.js

import React, { useState } from 'react';
import { Button, Input, Form, message } from 'antd';
import MemberAddComponent from './MemberAddComponent';
import { inviteMember } from '../../services/member.service';

const AddUserSection = ({ project, onAddMember }) => {
  const [addUserVisible, setAddUserVisible] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [form] = Form.useForm();

  const handleAddUser = () => {
    setAddUserVisible(!addUserVisible);
  };

  const handleNewTeamInvite = (values) => {
    // Implement the logic to send an invite to the new team member
    // For example, make an API call here
    // After sending the invite, reset the form and hide it
    const { name, email } = values
    const [f_name, l_name = ""] = name.split(" ")
    const dataToPost = { f_name, l_name, email }
    setProcessing(true)
    inviteMember(dataToPost).then(res => {
      console.log('Invite form submitted with values:', res.data)
      onAddMember(project.id, res.data.user.id)
      form.resetFields();
      setAddUserVisible(false);
      // message.success('Invitation sent successfully');
    }).finally(() => {
      setProcessing(false)
    })
  };

  return (
    <>
      <div className="add-user-bar">
        <div className="add-user-search-bar">
          <MemberAddComponent project={project} onAddMember={onAddMember} />
        </div>
        <Button type="link" onClick={handleAddUser}>
          <i className="fi fi-rr-user-add" style={{ marginRight: '5px' }}></i>
          Add people
          {addUserVisible ? (
            <i className="fi fi-rr-angle-small-up"></i>
          ) : (
            <i className="fi fi-rr-angle-small-down"></i>
          )}
        </Button>
      </div>
      {addUserVisible && (
        <Form
          form={form}
          layout="vertical"
          onFinish={handleNewTeamInvite}
          className="invite-form"
        >
          <div className="form-row">
            <Form.Item
              name="name"
              rules={[{ required: true, message: 'Please enter the name' }]}
              className="form-item"
            >
              <Input readOnly={processing} placeholder="Name" />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: 'Please enter the email' },
                { type: 'email', message: 'Please enter a valid email' },
              ]}
              className="form-item"
            >
              <Input readOnly={processing} placeholder="Email" />
            </Form.Item>
          </div>
          <Form.Item className="form-item-submit">
            <Button loading={processing} type="primary" htmlType="submit">
              Send Invite
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};


export default AddUserSection;

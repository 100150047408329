// File Path: src\Modals\TeamMembersModal\MemberAddComponent.js

import React from 'react';
import MemberPicker from './MemberPicker';
const MemberAddComponent = ({ project, onAddMember }) => {
  const onTeamMemberAdd = (newSelectedMember) => {
    onAddMember(project.id, newSelectedMember.id);
  };

  return (
    <MemberPicker placeholder="Search Team Member To Add" onChange={onTeamMemberAdd} />
  );
};

export default MemberAddComponent;

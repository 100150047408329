// File Path: src\Modals\TeamMembersModal\TeamMembersModal.js

import React from 'react';
import { Modal, Tabs, List } from 'antd';
import SingleAvatar2 from '../../Components/SingleAvatar2';
import { Link } from 'react-router-dom';
import { appSubDir } from '../../config';
import AddUserSection from './AddUserSection';
import MemberComponent from './MemberComponent';
import { useSelector } from 'react-redux';
import { hasRole } from '../../services/auth.service';

const { TabPane } = Tabs;
const TeamMembersModal = ({
  project,
  visible,
  onClose,
  onAddMember,
  onRemoveMember,
}) => {
  const user = useSelector((state) => state.auth.user);
  const isSuperAdmin = hasRole(user, 'super-admin');

  return (
    <Modal
      title={
        <div className="modal-title-container">
          <div className="modal-icon-container">
            <i className="fi fi-rr-team-check modal-icon"></i>
          </div>
          {`Project : ${project ? project.name : ''}`}
        </div>
      }
      visible={visible}
      onCancel={onClose}
      footer={false}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="Team" key="1">
          {isSuperAdmin && <AddUserSection project={project} onAddMember={onAddMember} />}
          <List
            itemLayout="horizontal"
            dataSource={project.members}
            renderItem={(member) => (
              <MemberComponent
                project={project}
                member={member}
                isSuperAdmin={isSuperAdmin}
                onRemoveMember={onRemoveMember}
              />
            )}
          />
        </TabPane>
        <TabPane tab="Client" key="2">
          {/* Add your client-related UI here */}
          <List
            itemLayout="horizontal"
            dataSource={[project.client]}
            renderItem={(client) => (
              <List.Item
                actions={
                  isSuperAdmin
                    ? [
                      <Link
                        to={`${appSubDir}clients/${client.id}/profile`}
                        className="remove-button"
                      >
                        View Profile
                      </Link>,
                    ]
                    : undefined
                }
              >
                <List.Item.Meta
                  avatar={
                    <SingleAvatar2
                      name={`${client.contact.f_name} ${client.contact.l_name}`}
                    />
                  }
                  title={`${client.contact.f_name} ${client.contact.l_name}`}
                  description={client.company_name}
                />
              </List.Item>
            )}
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default TeamMembersModal;

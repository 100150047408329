import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Input, Select, Spin, notification } from 'antd';
import moment from 'moment'; // for handling date inputs
import { useProjectsContext } from '../../Context/ProjectsContext';
import { companyAutocomplete } from '../../services/client.service';
import { addProject, updateProject } from '../../services/project.service';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getRoleName } from '../../services/auth.service';

const { Option } = Select;
const ProjectUpsertModal = ({ project }) => {
    const user = useSelector((state) => state.auth.user);
    const role = getRoleName(user)
    const isEdit = (project !== undefined)
    const { client_id } = useParams()
    const client = isEdit && project.client ? { ...project.client } : null
    const { projects, setProjects } = useProjectsContext()
    const [isVisible, setIsVisible] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [selected, setSelected] = useState(client);
    const [options, setOptions] = useState(client ? [client] : []);
    const [fetching, setFetching] = useState(false);
    const [form] = Form.useForm();
    useEffect(() => {
        if (isEdit && project) {
            form.setFieldsValue({
                name: project.name,
                client_id: project.client ? project.client.id.toString() : undefined,
            });
        }
    }, [form, isEdit, project]);
    function toggleModal() {
        setIsVisible(!isVisible)
    }
    const handleSearch = (searchValue) => {
        if (searchValue.length >= 2) {
            setFetching(true);
            companyAutocomplete(searchValue).then((response) => {
                setOptions(response.data);
                setFetching(false);
            }).catch(() => {
                setFetching(false);
            });
        }
    };

    // Handles the form submission
    const handleSubmit = async (values) => {
        // Here you might call an API to add the project
        // console.log('Form Values:', values);
        const dataToPost = {}
        dataToPost.name = values.name
        if (client_id) {
            dataToPost.client_id = client_id
        } else {
            dataToPost.client_id = values.client_id
        }
        setDisabled(true)
        if (isEdit) {
            updateProject(project.id, dataToPost,role).then((res) => {
                const index = projects.findIndex((item) => (item.id === project.id))
                if (index < 0) return
                projects[index] = { ...res.data }
                setProjects([...projects])
                // Assuming the API call is successful, you might want to do:
                notification.success({
                    message: 'Project Update',
                    description: 'The project has been successfully update.',
                });
                form.resetFields(); // Reset form fields after submission
                setIsVisible(false); // Trigger any additional actions on success

            }).finally(() => {
                setDisabled(false)
            })
        } else {
            addProject(dataToPost.name, dataToPost.client_id).then((res) => {
                projects.splice(0, 0, res.data)
                setProjects([...projects])
                // Assuming the API call is successful, you might want to do:
                notification.success({
                    message: 'Project Added',
                    description: 'The project has been successfully added.',
                });
                form.resetFields(); // Reset form fields after submission
                setIsVisible(false); // Trigger any additional actions on success

            }).finally(() => {
                setDisabled(false)
            })
        }
    };

    // Handles the form cancel action
    const handleCancel = () => {
        form.resetFields();
        toggleModal(); // Close the modal
    };
    const handleChange = (newValue) => {
        const newSelected = options.find(option => option.id.toString() === newValue) || null;
        setSelected(newSelected);
    };

    return (
        <>
            {isEdit ? <Button type="link" onClick={toggleModal}>
                <i class="fi fi-rr-pencil"></i>
            </Button> : <Button key="submit" type="primary" onClick={toggleModal}>
                Add Project
            </Button>}
            <Modal
                title={(isEdit ? "Update" : "Add New") + " Project"}
                open={isVisible}
                onCancel={handleCancel}
                footer={[
                    <Button disabled={disabled} key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button disabled={disabled} key="submit" type="primary" onClick={() => form.submit()}>
                        {isEdit ? "Update" : "Add"}  Project
                    </Button>,
                ]}
            >
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    layout="vertical"
                    initialValues={{ date: moment() }} // Example initial value for date
                >
                    <Form.Item
                        name="name"
                        label="Project Name"
                        rules={[{ required: true, message: 'Please input the project name!' }]}
                    >
                        <Input value={isEdit ? project.name : ""} placeholder="Enter project name" />
                    </Form.Item>
                    {client_id ? null : <Form.Item
                        name="client_id"
                        label={"Company Name"}
                        rules={[{ required: true, message: 'Please input the project name!' }]}
                    >
                        <Select
                            showSearch
                            value={selected ? selected.id.toString() : undefined}
                            placeholder={"Search Company Name"}
                            notFoundContent={fetching ? <Spin size="small" /> : null}
                            filterOption={false}
                            onSearch={handleSearch}
                            onChange={handleChange}
                            optionLabelProp="label"
                        >
                            {options.map(option => (
                                <Option key={option.id} value={option.id.toString()} label={option.company_name}>
                                    {option.company_name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>}
                </Form>
            </Modal>
        </>
    );
};

export default ProjectUpsertModal;

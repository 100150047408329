// file path : src\Components\FullPageSpinner.js

import React from 'react';
import { Spin } from 'antd';
import './FullPageSpinner.less'; // Import the stylesheet
import indicatorIcon from './CustomSpinnerIcon ';

const FullPageSpinner = () => {
  return (
    <div className="fullPageSpinner">
      <Spin size="large" indicator={indicatorIcon}/>
    </div>
  );
};

export default FullPageSpinner;


// File Path: src\Pages\Projects\ProjectsTableColumns\TeamMembersModalWrapper.js

import React, { useState } from 'react';
import { message } from 'antd';
import { addMember, removeMember } from '../../../services/project.service';
import { useProjectsContext } from '../../../Context/ProjectsContext';
import TeamMembersModal from '../../../Modals/TeamMembersModal/TeamMembersModal';
import AddMemberButton from '../../../Components/AddMemberButton';

const TeamMembersModalWrapper = ({ project }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { projects, setProjects } = useProjectsContext();

  const onAddMember = (projectId, memberId) => {
    addMember(projectId, memberId)
      .then((res) => {
        const projectIndex = projects.findIndex((item) => item.id === projectId);
        projects[projectIndex] = { ...res.data };
        setProjects([...projects]);
        message.success('Team Member Added');
      })
      .catch((err) => {
        console.log('onAddMember failed', err);
        message.error('Team Member Add Failed');
      });
  };

  const onRemoveMember = (projectId, userId) => {
    return removeMember(projectId, userId)
      .then(() => {
        const projectIndex = projects.findIndex((item) => item.id === projectId);
        const memberIndex = projects[projectIndex].members.findIndex(
          (item) => item.user_id === userId
        );
        projects[projectIndex].members.splice(memberIndex, 1);
        setProjects([...projects]);
        message.success('Team Member Removal Successful');
      })
      .catch((err) => {
        console.log('onRemoveMember failed', err);
        message.error('Team Member Removal Failed');
        throw err;
      });
  };

  const toggleModal = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      <AddMemberButton project={project} handleAddMember={toggleModal} />
      <TeamMembersModal
        project={project}
        visible={isVisible}
        onClose={toggleModal}
        onAddMember={onAddMember}
        onRemoveMember={onRemoveMember}
      />
    </>
  );
};

export default TeamMembersModalWrapper;

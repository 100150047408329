import axios from "axios";
import { apiBaseUrl } from "../config";
import { getAuthHeader } from "./auth.service";

export function createCheckOutSession(dataToPost) {
    return axios.post(apiBaseUrl + "company/subscriptions/create-session", dataToPost, getAuthHeader())
}
export function buySubscription(payment_intend_id){
    return axios.post(apiBaseUrl + "company/subscriptions/buy", { payment_intend_id }, getAuthHeader())
}
export function saveCardAction(title, payment_method_id) {
    return axios.post(apiBaseUrl + "company/payment/save-card", { title, payment_method_id }, getAuthHeader())
}
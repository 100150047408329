import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import getStatusTag from '../../../Components/StatusTags2';
import { useProjectContext } from '../../../Context/ProjectContext';

const { Option } = Select;

const EditableStatusCell = ({
  value: initialValue,
  row,
  column
}) => {
  const [value, setValue] = useState(initialValue);
  const { update } = useProjectContext();
  const isSubtask = !!row.original.subtaskId

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChange = (value) => {
    setValue(value);
    if (isSubtask) {
      update.subTask(row.original, column.id, value)
    } else {
      update.task(row.original, column.id, value)
    }
  };

  return (
    <Select
      value={value}
      onChange={onChange}
      style={{ width: '100%' }}
      dropdownClassName="status-dropdown"
    >
      <Option value="Not Started">{getStatusTag("Not Started")}</Option>
      {/* <Option value="On Hold">{getStatusTag("On Hold")}</Option> */}
      <Option value="In Progress">{getStatusTag("In Progress")}</Option>
      {/* <Option value="In Review">{getStatusTag("In Review")}</Option> */}
      <Option value="Completed">{getStatusTag("Completed")}</Option>

    </Select>
  );
};

const StatusColumn = {
  Header: 'Status',
  accessor: 'status',
  width: 10,
  Cell: EditableStatusCell,
  className: 'pmt-status-column',
};

export default StatusColumn;

import React, { useState, useEffect, useRef } from 'react';
import { Layout, Menu } from 'antd';
import ProjectManagementTable from './ProjectManagementTable';
import '../../custom.less'; // Ensure custom.less is imported to apply styles
import './WorkPages.less';
import { ProjectProvider, useProjectContext } from '../../Context/ProjectContext';
import ProjectOverview from './ProjectOverview';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import NotFound from '../NotFound';
import SkeletonTable from '../../Components/SkeletonTable';
import { fetchProject } from '../../services/project.service';
import { fetchTasks } from '../../services/project-task.service';
import { useSelector } from 'react-redux';
import { getRoleName, hasRole } from '../../services/auth.service';
import { appSubDir } from '../../config';
import { beforeSetTasks } from '../../Utils/taskActions';

const { Content } = Layout;

const MainComponent = () => {
  const user = useSelector((state) => state.auth.user);
  const role =  getRoleName(user)
  const isCLient = hasRole(user, 'client')
  const { id, tabName } = useParams()
  const { project, setProject, setTasks, isTableLoading, setIsTableLoading, update } = useProjectContext()
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [view, setView] = useState(tabName ? tabName : 'overview'); // Default view is set to 'overview'
  const taskLoaded = useRef(false)
  const navigate = useNavigate()

  const updateIsMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
    return () => window.removeEventListener('resize', updateIsMobile);
  }, []);
  useEffect(() => {
    if (isCLient) {
      return () => { }
    }
    const timer = setTimeout(() => {
      update.fromLocal.sortByColumns(id)
      update.fromLocal.groupByColumns(id)
      setLoading(true)
      fetchProject(id, role).then((res) => {
        setProject(res.data)
      }).catch((err) => {
        console.error("Project Data Fetch Error", err)
      }).finally(() => {
        setLoading(false)
      })
    }, 1)
    return () => {
      clearTimeout(timer)
    }
  }, [id]);
  useEffect(() => {
    if (isCLient) {
      return () => { }
    }
    const timer = setTimeout(() => {
      if (tabName === "table" && !taskLoaded.current && !isTableLoading) {
        taskLoaded.current = true;
        setIsTableLoading(true)
        fetchTasks(id, role).then((tasks) => {
          beforeSetTasks(tasks)
          setTasks(tasks)
        }).catch((err) => {
          console.error("Project Data Fetch Error", err)
        }).finally(() => {
          setIsTableLoading(false)
        })
      }
    }, 1)
    return () => {
      clearTimeout(timer)
    }
  }, [tabName]);
  if (isCLient) {
    return <Navigate to={appSubDir + "nothing-found"} />
  }

  const handleMenuClick = (e) => {
    if (e.key === 'overview') {
      navigate(appSubDir + `projects/${id}`);
    } else {
      navigate(appSubDir + `projects/${id}/${e.key}`)
    }
  };
  if (loading) {
    return <SkeletonTable rows={8} columns={4} />
  }
  if (!project) {
    return <NotFound />
  }

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#f0f2f5' }}>
      <p className="ct-page-title">
        <span className="ct-page-title-icon">
          <i className="fi fi-rr-briefcase"></i>
        </span>
        {project.name}
      </p>

      <Content style={{ padding: '0px' }}>
        <Menu className="ap-menu" onClick={handleMenuClick} selectedKeys={[tabName ? tabName : "overview"]} mode="horizontal">
          <Menu.Item key="overview" className="menu-item-with-icon">
            <i className="fi fi-rr-memo-pad"></i>
            Overview
          </Menu.Item>
          <Menu.Item key="table" className="menu-item-with-icon">
            <i className="fi fi-rr-table-list"></i>
            Table
          </Menu.Item>
          {/*
          <Menu.Item key="kanban" className="menu-item-with-icon">
            <i className="fi fi-rr-rectangles-mixed"></i>
            Kanban
          </Menu.Item>
          <Menu.Item key="gantt" className="menu-item-with-icon">
            <i className="fi fi-rr-chart-gantt"></i>
            Gantt
          </Menu.Item>
          <Menu.Item key="calendar" className="menu-item-with-icon">
            <i className="fi fi-rr-calendar"></i>
            Calendar
          </Menu.Item>
          */}
        </Menu>
        {tabName === 'table' ? <ProjectManagementTable /> : <ProjectOverview />}
      </Content>

    </Layout>

  );
};

const IndividualProject = () => {
  return (
    <ProjectProvider>
      <MainComponent />
    </ProjectProvider>
  )
}
export default IndividualProject;

// File path: src/Pages/WorkPages/TableSingle.js

import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import { useProjectContext } from '../../Context/ProjectContext';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import columns, { groupByOptions } from './TableColumns/TableColumn'; // Import column definitions and group by options
import './WorkPages.less';
import PGGroupTitleField from './PGGroupTitleField';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

/**
 * TableSingle Component
 *
 * Renders a table of tasks, optionally grouped by a specific task group.
 * Utilizes react-table for table functionality and Ant Design for UI components.
 *
 * @param {Object} props - Component props
 * @param {number} [props.groupIndex] - Optional index of the task group to display
 * @returns {JSX.Element} The rendered table component
 */
const TableSingle = ({ groupIndex }) => {
  // Extract necessary state and actions from the ProjectContext
  const {
    tasks,          // All tasks
    taskGroups,     // Array of task groups
    groupByColumns, // Columns used for grouping
    sortByColumns,  // Columns used for sorting
    columnOrder,    // Order of columns
    project,        // Current project details
    add             // Add actions (e.g., add.task)
  } = useProjectContext();

  // Determine the current group based on the provided groupIndex
  const group = groupIndex !== undefined ? taskGroups[groupIndex] : null;

  /**
   * Memoize the ordered columns based on the columnOrder array.
   * This ensures that the columns are rendered in the specified order.
   */
  const orderedColumns = useMemo(() => 
    columnOrder.map((columnId) =>
      columns.find((col) => col.accessor === columnId)
    ), 
    [columnOrder]
  );

  /**
   * Handler to add a new task to the current group.
   * Invokes the add.task method from the context with the current groupIndex.
   */
  const handleAddTask = () => {
    add.task(groupIndex);
  };

  /**
   * Initialize the react-table instance with the ordered columns and data.
   * If a group is specified, use its tasks; otherwise, use all tasks.
   */
  const {
    getTableProps,     // Function to get table props
    getTableBodyProps, // Function to get table body props
    headerGroups,      // Array of header groups for rendering headers
    rows,              // Array of rows to be displayed
    prepareRow,        // Function to prepare a row for rendering
  } = useTable(
    {
      columns: orderedColumns,                // Columns configuration
      data: group ? group.tasks : tasks,      // Data to be displayed in the table
      groupIndex                              // Current group index (if any)
    }
  );

  /**
   * Formats the label for a given section key.
   *
   * @param {string} key - The key of the section attribute
   * @returns {string} The formatted label for the section
   */
  function getSectionLabel(key) {
    // Special handling for the 'assignee' key to display the user's full name
    if (key === "assignee") {
      const assignee_id = group.attributes[key];
      const assignee = project.members.find((item) => item.user_id === assignee_id);
      if (assignee) {
        return assignee.user.f_name + " " + assignee.user.l_name;
      }
    }
    // Default: return the attribute value as is
    return group.attributes[key];
  }

  /**
   * Renders the table structure including headers, rows, and the "Add Task" button.
   *
   * @returns {JSX.Element} The rendered table
   */
  function getTable() {
    return (
      <table {...getTableProps()} >
        <thead>
          {/* Render each header group using the TableHeader component */}
          {headerGroups.map((headerGroup) => (
            <TableHeader key={headerGroup.id} headerGroup={headerGroup} />
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {/* Render each row using the TableRow component */}
          {rows.map((row, index) => (
            <TableRow
              key={row.original.taskId}    // Unique key for each row
              row={row}                    // Row data
              index={index}                // Row index
              prepareRow={prepareRow}      // Function to prepare the row
            />
          ))}
          {/* Render a row with a button to add a new task */}
          <tr className="pmt-add-task-row">
            <td colSpan={orderedColumns.length} className="pmt-add-task-cell">
              <Button
                icon={<PlusOutlined />}               // Icon for the button
                onClick={handleAddTask}               // Click handler
                className="pmt-add-task-button"       // CSS class for styling
              >
                Add Task
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  // Conditional rendering based on whether a group is specified
  if (group) {
    return (
      <div className='pmt-group-table'>
        {/* Render group attributes as headers */}
        <div className='header'>
          {Object.keys(group.attributes).map((key, index) => (
            <span key={index}>
              <b>{groupByOptions.find((item) => item.key === key)?.label} :</b>
              {/* If the key is 'section', render the PGGroupTitleField component */}
              {key === "section" ? (
                <PGGroupTitleField groupIndex={groupIndex} />
              ) : (
                getSectionLabel(key) // Otherwise, render the formatted section label
              )}
            </span>
          ))}
        </div>
        {/* Render the table */}
        {getTable()}
      </div>
    );
  } else {
    // If no group is specified, render only the table
    return <>{getTable()}</>;
  }
};

// Export the component as the default export
export default TableSingle;

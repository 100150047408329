import axios from "axios";
import { apiBaseUrl } from "../config";
import { getApiPrefix, getAuthHeader, hasRole } from "./auth.service";

export function canEditProject(project, user) {
    if (hasRole(user, 'super-admin')) {
        return true;
    } else if (project?.client?.manager_id === user.id) {
        return true;
    } else {
        return false;
    }
}
export function fetchProjects(page = 0, rowsPerPage = 20, sortByColumns = [], role = 'super-admin') {
    const query = new URLSearchParams("")
    if (page) {
        query.set('page', page.toString());
    }
    if (rowsPerPage) {
        query.set('per_page', rowsPerPage.toString());
    }
    if (sortByColumns && sortByColumns.length > 0) {
        sortByColumns.forEach(element => {
            if (element.key && element.order)
                query.set(element.key, element.order);
        });
    }
    let url = [
        apiBaseUrl + getApiPrefix(role) + "projects",
        query.toString()
    ].join("/?")
    return axios.get(url, getAuthHeader())
}
export function fetchProject(projectId, role = 'super-admin') {
    return axios.get(apiBaseUrl + getApiPrefix(role) + `projects/${projectId}`, getAuthHeader())
}
export function updateProject(projectId, values, role = 'super-admin') {
    return axios.put(apiBaseUrl + getApiPrefix(role) + `projects/${projectId}`, values, getAuthHeader())
}
export function removeMember(projectId, user_id) {
    return axios.delete(apiBaseUrl + `projects/${projectId}/members/${user_id}`, getAuthHeader())
}
export function addMember(projectId, user_id) {
    return axios.post(apiBaseUrl + `projects/${projectId}/member`, { user_id }, getAuthHeader())
}
export function addProject(name, client_id) {
    return axios.post(apiBaseUrl + `projects`, { name, client_id }, getAuthHeader())
}

export function createProject(values) {
    return axios.post(apiBaseUrl + 'projects', values, getAuthHeader())
}

export function projectAutocomplete(value) {
    return axios.get(apiBaseUrl + `projects/autocomplete/${value}`, getAuthHeader())
}

import React from 'react';
import { Button, List, message, Popconfirm } from 'antd';
import { storageBaseUrl } from '../../config';
import { DeleteOutlined } from '@ant-design/icons';
import { ProjectTaskDocumentService } from '../../services/project-task.service';
import { truncateWithEllipsis } from '../../services/common.functions';
import { getRoleName } from '../../services/auth.service';
import { useSelector } from 'react-redux';

const TaskAttachments = ({ attachments, setAttachments }) => {
  const user = useSelector((state) => state.auth.user);
  const role = getRoleName(user);
  // Optional: Handle delete confirmation feedback
  const handleDelete = (item, index) => {
    attachments.splice(index, 1)
    setAttachments([...attachments])

    const fs = new ProjectTaskDocumentService(item.project_id, item.task_id, role)
    fs.delete(item.document_id)
      .then(() => {
        attachments.slice(index, 1)
        setAttachments([...attachments])
        message.success(`${item.file_name} deleted successfully`);
      })
      .catch((error) => {
        message.error(`Failed to delete ${item.file_name}: ${error.message}`);
      });
  };
  return (
    <div className="task-attachments">
      <List
        dataSource={attachments}
        renderItem={(item, index) => (
          <List.Item
            actions={[
              <Popconfirm
                title={`Are you sure you want to delete "${item.file_name}"?`}
                onConfirm={() => handleDelete(item, index)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link" danger icon={<DeleteOutlined />}>
                  Delete
                </Button>
              </Popconfirm>,
            ]}>
            <a href={storageBaseUrl + `project-task-documents/${item.file_name}`} download={item.name}>
              {truncateWithEllipsis(item.file_name, 50)}
            </a>
          </List.Item>
        )}
      />
    </div>
  );
};

export default TaskAttachments;

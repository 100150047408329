import React, { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Dropdown,
  Input,
  Popconfirm,
  Space,
  Upload,
  message,
} from "antd";
import AddTaskDescription from "./AddTaskDescription";
import TaskAttachments from "./TaskAttachments";
import TaskSummary from "./TaskSummary";
import ModalSubtasks from "./ModalSubtasks";
import TaskComments from "./TaskComments";
import "../Modals.less";
import "../../custom.less";
import { useProjectContext } from "../../Context/ProjectContext";
import { ProjectTaskDocumentService } from "../../services/project-task.service";
import { useSelector } from "react-redux";
import { getRoleName } from "../../services/auth.service";
import { DeleteOutlined } from "@ant-design/icons";

const TaskDetailsModal = ({ taskIndex, visible, setVisible }) => {
  const user = useSelector((state) => state.auth.user);
  const role = getRoleName(user);
  const [attachments, setAttachments] = useState([]);
  const { tasks, project, update, remove } = useProjectContext();
  const task = tasks[taskIndex];
  function onTaskUpdate(key, value, subtaskIndex = -1) {
    console.log({ key, value, subtaskIndex });
    if (subtaskIndex === -1) {
      // console.log("TaskDetailsModal",task.taskIndex)
      update.task(task, key, value);
    } else {
      update.subTask(task.subtasks[subtaskIndex], key, value);
    }
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      const fs = new ProjectTaskDocumentService(project.id, task.task_id, role);
      fs.index().then((response) => {
        setAttachments(response.data);
      });
    }, 10);
    return () => {
      clearTimeout(timer, project);
    };
  }, [task.task_id]);

  const handleUpload = ({ file }) => {
    if (file.status === "done") {
      const fs = new ProjectTaskDocumentService(project.id, task.task_id, role);
      const formData = new FormData();
      formData.append("file_name", file.originFileObj);
      fs.store(formData)
        .then((response) => {
          message.success(`${file.name} file uploaded successfully`);
          setAttachments([...attachments, response.data]);
        })
        .catch(() => {
          message.error(`${file.name} file upload failed.`);
        });
    } else if (file.status === "error") {
      message.error(`${file.name} file upload failed.`);
    }
  };

  function onClose() {
    setVisible(false);
  }

  function handleTaskNameChange(e) {
    onTaskUpdate("taskName", e.target.value);
  }

  // *delete task
  const handleDelete = () => {
    remove.task(taskIndex);
    onClose();
    message.info("Task deleted successfully");
  };

  // *dropdown items
  const menuItems = [
    {
      label: (
        <div>
          <Popconfirm
            title="Are you sure you want to delete?"
            onConfirm={handleDelete}
            okText="Yes"
            cancelText="No"
            placement="bottom"
          >
            <Button
              style={{ padding: 0 }}
              type="link"
              danger
              icon={<DeleteOutlined />}
            >
              Delete
            </Button>
          </Popconfirm>
        </div>
      ),
      key: "0",
    },
  ];

  return (
    <Drawer
      placement="right"
      width={740}
      onClose={onClose}
      open={visible}
      className="task-details-modal"
    >
      <div className="task-details-topbar">
        <div className="task-details-left">
          <span className="task-icon modal-icon-container">
            <i className="fi fi-rr-memo-pad modal-icon"></i>
          </span>
          <span className="task-text">Task Details</span>
        </div>
        <div className="task-details-right">
          <span className="small-button">
            <i class="fi fi-rr-clone"></i>
          </span>
          <Upload
            multiple={false}
            customRequest={({ file, onSuccess }) =>
              setTimeout(() => onSuccess("ok"), 0)
            }
            onChange={handleUpload}
            showUploadList={false}
          >
            <span className="small-button">
              <i class="fi fi-rr-paperclip-vertical"></i>
            </span>
          </Upload>
          <Dropdown menu={{ items: menuItems }} trigger={["click"]}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <span className="small-button">
                  <i className="fi fi-rr-menu-dots"></i>
                </span>
              </Space>
            </a>
          </Dropdown>
          {/* <span className="small-button"><i className="fi fi-rr-menu-dots"></i></span> */}
          <span className="tdm-close-icon" onClick={onClose}>
            <span className="small-button">
              <i className="fi fi-rr-exit"></i>
            </span>
          </span>
        </div>
      </div>

      <div className="tdm-modal-body">
        <Input
          className="tdm-task-cell-content border-1"
          defaultValue={task.taskName}
          onBlur={handleTaskNameChange}
        />

        <TaskSummary {...{ task, onTaskUpdate, project }} />
        <AddTaskDescription task={task} onTaskUpdate={onTaskUpdate} />
        <ModalSubtasks {...{ task, taskIndex, onTaskUpdate, project, tasks }} />

        <div className="task-attachments-section">
          <TaskAttachments
            attachments={attachments}
            setAttachments={setAttachments}
          />
        </div>
      </div>
      <TaskComments taskIndex={taskIndex} />
    </Drawer>
  );
};

export default TaskDetailsModal;

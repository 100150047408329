import React from 'react';
import { Tooltip } from 'antd';
import { useProjectContext } from '../../../Context/ProjectContext';

const AddSubTaskButton = ({ taskIndex, subtasks, isExpanded, setIsExpended }) => {
  const { add } = useProjectContext();

  function handleAddSubTask() {
    if (!isExpanded) {
      setIsExpended(true);
    }
    add.subTask(taskIndex);
  }

  return (
    <Tooltip title="Add Sub task">
      <span className="pmt-task-details-icon" onClick={handleAddSubTask}>
        <i className="fi fi-rr-add"></i>
      </span>
    </Tooltip>
  );
};

export default AddSubTaskButton;

import React, { useState, useEffect } from 'react';
import { DatePicker, ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import '../WorkPages.less';
import { useProjectContext } from '../../../Context/ProjectContext';

const EditableDueDateCell = ({
  value: initialValue,
  row,
  column
}) => {
  const { update } = useProjectContext();
  const [value, setValue] = useState(initialValue ? dayjs(initialValue) : null);
  const isSubtask = !!row.original.subtaskId

  useEffect(() => {
    setValue(initialValue ? dayjs(initialValue) : null);
  }, [initialValue]);

  const onChange = (date) => {
    setValue(date);
    if (isSubtask) {
      update.subTask(row.original, column.id, date?.format('YYYY-MM-DD') || null)
    } else {
      update.task(row.original, column.id, date?.format('YYYY-MM-DD') || null)
    }
  };

  const customDatePickerStyle = {
    '.ant-picker-cell-selected .ant-picker-cell-inner': {
      backgroundColor: '#4CAF50',
      color: 'white'
    },
    '.ant-picker-cell-today .ant-picker-cell-inner::before': {
      borderColor: '#2196F3'
    },
    '.ant-picker-cell-today .ant-picker-cell-inner': {
      color: '#2196F3'
    }
  };

  return (
    <ConfigProvider locale="en">
      <DatePicker
        value={value}
        onChange={onChange}
        style={{ width: '100%' }}
        format="YYYY-MM-DD"
        getPopupContainer={(trigger) => trigger.parentNode} // Ensures the popup is within the table container
        popupStyle={customDatePickerStyle} // Custom styles for the date picker
      />
    </ConfigProvider>
  );
};

const DueDateColumn = {
  Header: 'Due Date',
  accessor: 'dueDate',
  width: 10,
  Cell: EditableDueDateCell,
  className: 'pmt-dueDate-column',
};

export default DueDateColumn;

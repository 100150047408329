// File Path : src\services\search.service.js

import axios from "axios";
import { apiBaseUrl } from "../config";
import { getAuthHeader } from "./auth.service";

export function superAdminSearch(niddle) {
    const query = new URLSearchParams("")
    query.set('query', niddle.toString());
    return axios.get([apiBaseUrl + "search", query.toString()].join("/?"), getAuthHeader())
}
export function companySearch(niddle) {
    const query = new URLSearchParams("")
    query.set('query', niddle.toString());
    return axios.get([apiBaseUrl + "company/search", query.toString()].join("/?"), getAuthHeader())
}
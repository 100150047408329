import React from 'react';
import { DatePicker, ConfigProvider, message } from 'antd';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/en_GB'; // Ensure the correct locale is used
import { canEditProject, updateProject } from '../../../services/project.service';
import { useProjectsContext } from '../../../Context/ProjectsContext';
import { useSelector } from 'react-redux';
import { getRoleName, hasRole } from '../../../services/auth.service';

const { RangePicker } = DatePicker;

const countWorkdays = (startDate, endDate) => {
  let count = 0;
  let current = startDate.clone();

  while (current <= endDate) {
    if (current.day() !== 0 && current.day() !== 6) { // Exclude Sundays (0) and Saturdays (6)
      count++;
    }
    current = current.add(1, 'day');
  }

  return count;
};

const DateColumn = ({ record, index }) => {
  const user = useSelector((state) => state.auth.user);
  const role = getRoleName(user)
  const {
    projects, setProjects
  } = useProjectsContext()
  const startDate = dayjs(record.start_date);
  const endDate = dayjs(record.end_date);
  const today = dayjs();

  const projectDuration = countWorkdays(startDate, endDate);
  const daysLeft = countWorkdays(today, endDate);

  const handleDateChange = (index, id, dates) => {
    if (dates === null) {
      dates = [dayjs(), dayjs()]
    }
    const [startDate, endDate] = dates;
    // console.log({startDate:startDate.format('YYYY-MM-DD'), endDate:endDate.format('YYYY-MM-DD')})
    const start_date = startDate.format('YYYY-MM-DD')
    const end_date = endDate.format('YYYY-MM-DD')
    updateProject(projects[index].id, { start_date, end_date },role).catch((err) => {
      console.log("update project dates Failed", err)
      message.error("Update project dates Failed", 2000)
    })
    projects[index].start_date = start_date
    projects[index].end_date = end_date
    setProjects([...projects]);
  };
  return (
    <ConfigProvider locale={locale}>
      <div>
        {!canEditProject(record,user) ?
          <div style={{paddingBottom:10}}>
            <span>{startDate.format('DD/MM/YY')}</span> {/* Display the start date */}
            <span className="arrow">→</span> {/* Display an arrow between the dates */}
            <span>{endDate.format('DD/MM/YY')}</span> {/* Display the end date */}
          </div>
          :
          <RangePicker
            className="pt-date-range-picker"
            value={[startDate, endDate]}
            onChange={(dates) => handleDateChange(index, 'date', dates)}
            format="DD/MM/YY"
            getPopupContainer={(trigger) => trigger.parentNode}
          />}
        <div className="pt-workdays-container">
          <span className="pt-total-workdays-text">
            <span className="pt-timeline-value-text">{projectDuration}</span> Workdays Total.
          </span>
          <span className="pt-workdays-left-text">
            {daysLeft > 0 ? (
              <>
                <span className="pt-timeline-value-text">{daysLeft}</span> Workdays Left.
              </>
            ) : (
              'Deadline Passed.'
            )}
          </span>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default DateColumn;

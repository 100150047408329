import moment from "moment/moment";

export function secondsToHHMM(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    // Ensure two-digit format
    const hoursString = hours.toString().padStart(2, '0');
    const minutesString = minutes.toString().padStart(2, '0');

    return `${hoursString}:${minutesString}`;
}
export function hoursToString(decimalHours) {
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 60);

    // Pad the minutes with leading zeros if necessary
    const paddedMinutes = minutes > 0 ? ":" + (minutes.toString().padStart(2, '0')) : "";

    // Pad the hours with leading zeros if necessary
    const paddedHours = hours.toString().padStart(2, '0')

    return `${paddedHours}${paddedMinutes}`;
}
export function hoursToString2(decimalHours) {
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 60);

    // Pad the minutes with leading zeros if necessary
    const components = []
    if (hours > 0) {
        components.push(hours + "h")
    }
    if (minutes > 0) {
        components.push(minutes + "m")
    }

    return components.join(" ");
}
export function stringToHours(timeString) {
    // Split the time string into hours and minutes
    timeString = timeString.replace("h ", ":").replace("m", "")
    let [hours = 0, minutes = 0] = timeString.split(':').map((item) => parseInt(item) || 0);

    // Convert hours and minutes into a single number with fractional hours
    let number = hours + (minutes / 60);

    return number.toFixed(2);
}

export function generateRandomPassword(length = 12) {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
    let password = "";
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
    }
    return password;
}

export function parseFloatFromStr(str) {
    const floatData = parseFloat(str)
    return floatData > 0 ? floatData.toFixed(2) : 0
}
export const dateFormat = {
    short: (dateStr) => {
        return moment(dateStr).format('MMM DD')
    },
    long: (dateStr) => {
        return moment(dateStr).format('DD MMM YY')
    }
}
export const dateTimeFormat = {
    short: (timeStr) => {
        return moment(timeStr).format('MMM DD, hh:mm A')
    },
    long: (timeStr) => {
        return moment(timeStr).format('DD MMM YYYY, hh:mm A')
    }
}
export function isPastDate(inputDate) {
    // Create a moment object for the input date
    const dateToCheck = moment(inputDate);

    // Compare using moment's 'isBefore' function to check if the date is before today
    return dateToCheck.isBefore(moment(), 'day');
}
export function isPastDateTime(inputDateTime) {
    // Create a moment object for the input date and time
    const dateTimeToCheck = moment(inputDateTime);

    // Compare using moment's 'isBefore' function to check if the datetime is before the current datetime
    return dateTimeToCheck.isBefore(moment());
}

export const enhanceElementAppearance = (selector, options = {}) => {
    const { focusInput = false, glow = true, startAfter = 10, stopAfter = 2000 } = options;

    setTimeout(() => {
        const element = document.querySelector(selector);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            if (glow) {
                element.classList.add('glow-effect');
                setTimeout(() => {
                    element.classList.remove('glow-effect');
                }, stopAfter);
            }
            if (focusInput) {
                element.querySelector('input')?.focus();
            }
        }
    }, startAfter);
};


export const formatTimeSpent = (value) => {
    if (!value) return '0h 0m';

    const parts = value.split(/[:hHmM]+/);
    let hours = parts.length > 1 ? parseInt(parts[0], 10) : 0;
    let minutes = parts.length > 1 ? parseInt(parts[1], 10) : parseInt(parts[0], 10);

    if (isNaN(hours)) hours = 0;
    if (isNaN(minutes)) minutes = 0;

    // Convert minutes over 60 to hours
    hours += Math.floor(minutes / 60);
    minutes = minutes % 60;

    return `${hours}h ${minutes}m`;
};
function insertCharFromEnd(str, char, posFromEnd) {
    // Calculate the index from the start of the string
    const posFromStart = str.length - posFromEnd;

    // Ensure the position is valid
    if (posFromStart < 0 || posFromStart > str.length) {
        return 'Position out of bounds';
    }

    // Insert the character at the calculated position
    return str.slice(0, posFromStart) + char + str.slice(posFromStart);
}
// By Mashfiqur Rahman
// export const parseInput = (value) => {
//     let str = value.replace(/\D/g, ''); // Remove non-digit characters

//     return insertCharFromEnd(str, ":", 2);
// };
export const parseInput = (value) => {
    let numbers = value.replace(/\D/g, ''); // Remove non-digit characters
    if (numbers.length > 4) numbers = numbers.slice(-4); // Keep only the last 4 digits

    let minutes = numbers.slice(-2);
    let hours = numbers.slice(0, -2) || '0';

    return `${hours}:${minutes.padStart(2, '0')}`;
};

export function truncateWithEllipsis(s, maxLength) {
    let result = s.substring(0, maxLength);
    if (s.length > maxLength) {
        result += '...';
    }
    return result;
}
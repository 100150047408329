import React, { useState,useEffect } from "react";
import {
  Descriptions,
  Input,
  DatePicker,
  Select,
  Button,
  Popconfirm,
  Tooltip,
  message,
} from "antd";
import SingleAvatar2 from "../../Components/SingleAvatar2";
import dayjs from "dayjs";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useProjectContext } from "../../Context/ProjectContext";

const { Option } = Select;

// Custom Input component for subtasks
const CustomInput = ({ subtask, subTaskIndex, handleSubtaskChange }) => {
  const [taskName, setTaskName] = useState(subtask.taskName);
  useEffect(()=>{
    setTaskName(subtask.taskName)
  },[subtask.sub_task_id])

  return (
    <Input
      value={taskName}
      onChange={(e) => setTaskName(e.target.value)}
      onBlur={(e) =>
        handleSubtaskChange(subTaskIndex, "taskName", e.target.value)
      }
      className="border-1 tdm-subtask-input"
    />
  );
};

const ModalSubtasks = ({ task, taskIndex, onTaskUpdate, project }) => {
  const { add, remove } = useProjectContext();

  // Add a subtask
  function onAddSubtask() {
    add.subTask(taskIndex, false, false);
  }

  // Handle changes in subtask data
  function handleSubtaskChange(subtaskIndex, key, value) {
    onTaskUpdate(key, value, subtaskIndex);
  }

  // Handle deleting a specific subtask
  function handleDelete(subtaskIndex) {
    remove.subTask(taskIndex, subtaskIndex).then(()=>{
      message.success("Sub Task Deleted")
    })
  }

  return (
    <div className="tdm-modal-subtasks">
      <Descriptions
        bordered
        column={1}
        title="Subtasks"
        className="tdm-descriptions"
      >
        {task.subtasks.map((subtask, subTaskIndex) => (
          <Descriptions.Item
            key={subTaskIndex}
            className="tdm-descriptions-item"
          >
            {/* Subtask input */}
            <CustomInput {...{ subtask, subTaskIndex, handleSubtaskChange }} />

            {/* Due date picker */}
            <DatePicker
              value={subtask.dueDate ? dayjs(subtask.dueDate) : null}
              onChange={(date) =>
                handleSubtaskChange(
                  subTaskIndex,
                  "dueDate",
                  date ? date.format("YYYY-MM-DD") : null
                )
              }
              format="YYYY-MM-DD"
              getPopupContainer={(trigger) => trigger.parentNode}
              className="border-1 tdm-due-date-picker"
            />

            {/* Assignee select */}
            <Select
              value={subtask.assignee}
              onChange={(value) =>
                handleSubtaskChange(subTaskIndex, "assignee", value)
              }
              className="border-1 tdm-assignee-select"
              dropdownClassName="tdm-assignee-dropdown"
            >
              {project.members.map((member) => (
                <Option key={member.user_id} value={member.user_id}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <SingleAvatar2
                      name={member.user.f_name + " " + member.user.l_name}
                    />
                    <span style={{ marginLeft: "8px" }}>
                      {member.user.f_name + " " + member.user.l_name}
                    </span>
                  </div>
                </Option>
              ))}
            </Select>

            {/* Delete subtask button */}
            <div>
              <Tooltip title="Delete">
                <Popconfirm
                  title="Are you sure you want to delete this subtask?"
                  onConfirm={() => handleDelete(subTaskIndex)} // Correctly pass subTaskIndex to handleDelete
                  okText="Yes"
                  cancelText="No"
                  placement="top"
                >
                  <Button
                    style={{ padding: 0 }}
                    type="link"
                    danger
                    icon={<DeleteOutlined />}
                  />
                </Popconfirm>
              </Tooltip>
            </div>
          </Descriptions.Item>
        ))}

        {/* Add subtask button */}
        <Descriptions.Item className="pmt-add-task-cell">
          <Button type="link" icon={<PlusOutlined />} onClick={onAddSubtask}>
            Add Sub Task
          </Button>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default ModalSubtasks;

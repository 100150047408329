import React, { useState, useEffect } from 'react';
import { Layout, message, Spin } from 'antd';
import BreadcrumbComponent from '../../Components/breadcrumb';
import ClientMainBanner from './ClientMainPageComponents/ClientMainBanner';
import ClientMainFolders from './ClientMainPageComponents/ClientMainFolders';
import './ClientsPages.less';
import { useParams } from 'react-router-dom';
import { fetchClient, fetchCompanyDashboard } from '../../services/client.service';
import { useSelector } from 'react-redux';
import { getRoleName, hasRole } from '../../services/auth.service';
import NotFound from '../NotFound';
import FullPageSpinner from '../../Components/FullPageSpinner';

const { Content } = Layout;

const ClientMainPage = () => {
  const user = useSelector((state) => state.auth.user);
  const isSuperAdmin = hasRole(user, 'super-admin')
  const { id } = useParams()
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);

  // Page Data Loader
  function loadData() {
    setLoading(true)
    const apiCall = id ? fetchClient(id, getRoleName(user)) : fetchCompanyDashboard()
    apiCall.then((res) => {
      const clientData = res.data
      setClient(clientData);
    }).catch(() => {
      message.error('Sorry, Nothing Found')
    }).finally(() => {
      setLoading(false)
    })
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      loadData()
    }, 1)
    return () => {
      clearTimeout(timer)
    }
  }, [id]);

  if (loading) {
    return <FullPageSpinner />
  }

  if (!client) {
    return <NotFound />
  }

  const breadcrumbItems = [
    { title: '', link: '/', icon: 'fi-rr-home' },
    { title: 'Clients', link: '/clients' },
    { title: client.company_name },
  ];

  return (
    <div className="ct-client-main-page-container">
      {isSuperAdmin && <BreadcrumbComponent className="no-padding space-bellow" items={breadcrumbItems} />}

      <Content className="ct-content">
        <ClientMainBanner company={client} />

        <div className="ct-avatar-group-container">
          <div className="ct-line"></div>
        </div>


        <ClientMainFolders company={client} />
      </Content>
    </div>
  );
};

export default ClientMainPage;
